// eslint-disable-next-line import/no-extraneous-dependencies
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import App from './App';
import './index.css';
import reportWebVitals from './reportWebVitals';
import ReduxProvider from './store/ReduxProvider';
import CustomThemeProvider from './themes/CustomThemeProvider';
import packageJson from '../package.json';

if (process.env.REACT_APP_ENV !== 'local') {
  Sentry.init({
    dsn: 'https://5f086510141f4c328668b42d955f0088@o1163897.ingest.sentry.io/6252514',
    integrations: [new BrowserTracing()],
    tracesSampleRate: 0.25,
    release: `${packageJson.name}@${packageJson.version}`,
  });
}

ReactDOM.render(
  <React.StrictMode>
    <ReduxProvider>
      <Router>
        <CustomThemeProvider>
          <App />
        </CustomThemeProvider>
      </Router>
    </ReduxProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

reportWebVitals();
