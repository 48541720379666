import React from 'react';

import { useTheme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import FullLogo from '../logos/FullLogo';

const HeaderLogo = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  const finalResult = matches ? <FullLogo color="white" sx={{ width: '140px', marginTop: '0.4rem' }} />
    : <FullLogo sx={{ marginTop: '0.4rem', width: '104px' }} />;
  return finalResult;
};

export default HeaderLogo;
