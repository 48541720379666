import { Logout, Settings, SupervisedUserCircle } from '@mui/icons-material';
import {
  Avatar, Divider, Link, ListItemIcon, Menu, MenuItem,
} from '@mui/material';
import React from 'react';
import FeedbackIcon from '@mui/icons-material/Feedback';
import DiscordIcon from '../../icons/DiscordIcon';

export interface HeaderAvatarProps {
  displayName: string;
  profileImageSrc?: string;
  hideProfile?: boolean;
  hideSettings?: boolean;

  onLogoutItemClicked(): any;

  onProfileItemClicked(): any;
}

function HeaderAvatar(props: HeaderAvatarProps) {
  const {
    displayName, profileImageSrc, onProfileItemClicked, onLogoutItemClicked,
    hideProfile = false,
    hideSettings = true,
  } = props;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div>
      <Avatar
        id="avatar-menu"
        aria-controls="avatar-menu"
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        alt={displayName}
        src={profileImageSrc || `https://eu.ui-avatars.com/api/?name=${displayName}`}
        sx={{
          ':hover': {
            cursor: 'pointer',
          },
        }}
      />
      <Menu
        id="user-actions-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'avatar-menu',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {!hideProfile && (
          <MenuItem onClick={() => {
            handleClose();
            onProfileItemClicked();
          }}
          >
            <ListItemIcon>
              <SupervisedUserCircle fontSize="small" />
            </ListItemIcon>
            Profile
          </MenuItem>
        )}
        {!hideSettings && (
          <MenuItem onClick={() => {
            handleClose();
            onProfileItemClicked();
          }}
          >
            <ListItemIcon>
              <Settings fontSize="small" />
            </ListItemIcon>
            Settings
          </MenuItem>
        )}
        <Divider />
        <MenuItem
          onClick={() => {
            handleClose();
          }}
        >
          <Link
            target="_blank"
            href="https://discord.gg/AmJgFpjr"
            sx={{
              color: 'inherit',
              display: 'flex',
              alignItems: 'center',
              textDecoration: 'none',
            }}
          >
            <ListItemIcon>
              <DiscordIcon fontSize="small" />
            </ListItemIcon>
            Discord Community
          </Link>
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
          }}
        >
          <Link
            target="_blank"
            href="https://forms.gle/itofEsb35BpYfojXA"
            sx={{
              color: 'inherit',
              display: 'flex',
              alignItems: 'center',
              textDecoration: 'none',
            }}
          >
            <ListItemIcon>
              <FeedbackIcon fontSize="small" />
            </ListItemIcon>
            Feedback Form
          </Link>
        </MenuItem>
        <Divider />
        <MenuItem onClick={() => {
          handleClose();
          onLogoutItemClicked();
        }}
        >
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </div>

  );
};

export default HeaderAvatar;
