import { Box, Chip, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { setIsLoading } from '../../store/reducers/global-app/global-app.reducer';
import {
  GrantByDateProps, setSearchTerm, setSelectedAreas,
  setSelectedBeneficiaries, setSelectedCountries,
  setSelectedLocalAuthorities, setSelectedMetropolitanCounties,
  setSelectedProvides, setSelectedRegions,
  setSelectedThemes,
} from '../../store/reducers/grant-by-date-reducer';

function FundFilterDisplay() {
  const dispatch = useAppDispatch();
  const [filterItems, setFilterItems] = useState([]);

  const {
    searchTerm,
    selectedThemes,
    selectedRegions,
    selectedBeneficiaries,
    selectedProvides,
    selectedLocalAuthorities,
    selectedMetropolitanCounties,
    selectedCountries,
    selectedAreas,
  } = useAppSelector<GrantByDateProps>((state) => state.grantsByDate);

  const handleDeleteChipClicked = (filterItem: any) => {
    const newFilters = filterItems
      .filter((item) => (item.value !== filterItem.value));
    switch (filterItem.label) {
      case 'Theme':
        dispatch(setSelectedThemes([...new Set(newFilters
          .filter((f) => f.value !== filterItem.value
            && f.label === 'Theme').map((e) => e.value))]));
        break;
      case 'Beneficiary':
        dispatch(setSelectedBeneficiaries([
          ...new Set(newFilters.filter((f) => f.value !== filterItem.value
            && f.label === 'Beneficiary').map((e) => e.value))]));
        break;
      case 'Provides':
        dispatch(setSelectedProvides([
          ...new Set(newFilters.filter((f) => f.value !== filterItem.value
            && f.label === 'Provides').map((e) => e.value))]));
        break;
      case 'Region':
        dispatch(setSelectedRegions([
          ...new Set(newFilters.filter((f) => f.value !== filterItem.value
            && f.label === 'Region').map((e) => e.value))]));
        break;
      case 'Local Authority':
        dispatch(setSelectedLocalAuthorities([
          ...new Set(newFilters.filter((f) => f.value !== filterItem.value
            && f.label === 'Local Authority').map((e) => e.value))]));
        break;
      case 'Country':
        dispatch(setSelectedCountries([
          ...new Set(newFilters.filter((f) => f.value !== filterItem.value
            && f.label === 'Country').map((e) => e.value))]));
        break;
      case 'Metropolitan County':
        dispatch(setSelectedMetropolitanCounties([
          ...new Set(newFilters.filter((f) => f.value !== filterItem.value
            && f.label === 'Metropolitan County').map((e) => e.value))]));
        break;
      case 'Term':
        dispatch(setSearchTerm(''));
        break;
      default:
        dispatch(setIsLoading(false));
        break;
    }
  };

  // Areas
  useEffect(() => {
    const filters = [...new Set([
      ...filterItems.filter((e) => (e.label !== 'Region')),
      ...selectedRegions.map((e) => ({ value: e, label: 'Region', displayValue: e })),
    ])];
    dispatch(setSelectedAreas(filters.map((e) => e.value)));
    setFilterItems(filters);
  }, [selectedRegions]);
  useEffect(() => {
    const filters = [...new Set([
      ...filterItems.filter((e) => (e.label !== 'Local Authority')),
      ...selectedLocalAuthorities.map((e) => ({ value: e, label: 'Local Authority', displayValue: e })),
    ])];
    dispatch(setSelectedAreas(filters.map((e) => e.value)));
    setFilterItems(filters);
  }, [selectedLocalAuthorities]);
  useEffect(() => {
    const filters = [...new Set([
      ...filterItems.filter((e) => (e.label !== 'Metropolitan County')),
      ...selectedMetropolitanCounties.map((e) => ({ value: e, label: 'Metropolitan County', displayValue: e })),
    ])];
    dispatch(setSelectedAreas(filters.map((e) => e.value)));
    setFilterItems(filters);
  }, [selectedMetropolitanCounties]);
  useEffect(() => {
    const filters = [...new Set([
      ...filterItems.filter((e) => (e.label !== 'Country')),
      ...selectedCountries.map((e) => ({ value: e, label: 'Country', displayValue: e })),
    ])];
    dispatch(setSelectedAreas(filters.map((e) => e.value)));
    setFilterItems(filters);
  }, [selectedCountries]);

  useEffect(() => {
    const filters = [...new Set([
      ...filterItems.filter((e) => (e.label !== 'Theme')),
      ...selectedThemes.map((e) => ({ value: e, label: 'Theme', displayValue: e })),
    ])];
    setFilterItems(filters);
  }, [selectedThemes]);
  useEffect(() => {
    const filters = [...new Set([
      ...filterItems.filter((e) => (e.label !== 'Beneficiary')),
      ...selectedBeneficiaries.map((e) => ({ value: e, label: 'Beneficiary', displayValue: e })),
    ])];
    setFilterItems(filters);
  }, [selectedBeneficiaries]);
  useEffect(() => {
    const filters = [...new Set([
      ...filterItems.filter((e) => (e.label !== 'Provides')),
      ...selectedProvides.map((e) => ({ value: e, label: 'Provides', displayValue: e })),
    ])];
    setFilterItems(filters);
  }, [selectedProvides]);
  useEffect(() => {
    const filters = [...new Set([
      ...filterItems.filter((e) => (e.label !== 'Term')),
    ])];
    if (searchTerm && searchTerm !== '') {
      filters.push({ value: searchTerm, label: 'Term', displayValue: searchTerm });
    }
    setFilterItems(filters);
  }, [searchTerm]);

  useEffect(() => {
    if (selectedAreas.length <= 0) {
      setFilterItems([]);
    }
  }, [selectedAreas])

  return (
    <>
      {' '}
      {filterItems.length > 0
        ? (
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              flexWrap: 'wrap',
              p: 2,
            }}
          >
            {filterItems.map((filterItem) => (
              <Chip
                key={`filter-${filterItem.label}-${filterItem.value}`}
                label={(
                  <Box
                    sx={{
                      alignItems: 'center',
                      display: 'flex',
                      '& span': {
                        fontWeight: 600,
                      },
                    }}
                  >
                    <span>
                      {filterItem.label}
                    </span>
                    :
                    {' '}
                    {filterItem.displayValue || filterItem.value}
                  </Box>
                )}
                onDelete={() => handleDeleteChipClicked(filterItem)}
                sx={{ m: 1 }}
                variant="outlined"
              />
            ))}
          </Box>
        )
        : (
          <Box sx={{ p: 3 }}>
            <Typography
              color="textSecondary"
              variant="subtitle2"
            >
              No filters applied
            </Typography>
          </Box>
        )}
    </>
  );
}

export default FundFilterDisplay;
