import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  pageIndex: 0,
  pageSize: 10,
  totalFilteredRecords: 0,
  totalRecords: 0,
  organisations: [],
  sortBy: 'name|||asc',
  confirmDeleteOrganisationDialogOpen: false,
  selectedOrganisation: null,
};

const organisationsSlice = createSlice({
  name: 'organisations',
  initialState,
  reducers: {
    resetOrganisationFilters(state) {
      return { ...state, ...initialState };
    },
    setOrganisationsPageIndex(state, { payload }) {
      return { ...state, pageIndex: payload };
    },
    setOrganisationsPageSize(state, { payload }) {
      return { ...state, pageSize: payload };
    },
    setRecordCounts(state, {
      payload: {
        totalFilteredRecords,
        totalRecords,
      },
    }) {
      return {
        ...state,
        totalFilteredRecords,
        totalRecords,
      };
    },
    setOrganisations(state, { payload }) {
      return {
        ...state,
        organisations: payload,
      };
    },
    setDialogOpen(state, { payload }) {
      return {
        ...state,
        confirmDeleteOrganisationDialogOpen: payload.dialogOpen,
        selectedOrganisation: payload.selectedOrganisation,
      };
    },
  },
});

export const {
  setOrganisationsPageIndex,
  setOrganisationsPageSize,
  resetOrganisationFilters,
  setOrganisations,
  setRecordCounts,
  setDialogOpen,
} = organisationsSlice.actions;
const organisationsReducer = organisationsSlice.reducer;
export default organisationsReducer;
