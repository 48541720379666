import * as Sentry from '@sentry/react';
import { createSlice } from '@reduxjs/toolkit';
import { client } from '../../../api/api';

export const initialState = {
  isAuthenticated: false,
  isValid: false,
  accessToken: null,
  profile: null,
  tempFunderId: undefined,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login(state, { payload }) {
      const { isAuthenticated, accessToken, profile } = payload;
      client.setHeader('Authorization', `Bearer ${accessToken}`);
      Sentry.setUser({ email: profile.email, username: profile.displayName });
      return {
        ...state, isAuthenticated, accessToken, profile, isValid: true,
      };
    },
    logout(state) {
      Sentry.configureScope((scope) => scope.setUser(null));
      return {
        ...state,
        tempFunderId: undefined,
        isAuthenticated: false,
        accessToken: null,
        profile: null,
        isValid: false,
      };
    },
    setTempFunderId(state, { payload }) {
      const { funderId } = payload;
      return { ...state, tempFunderId: funderId };
    },
  },
});

export const { login, logout, setTempFunderId } = authSlice.actions;
const authReducer = authSlice.reducer;
export default authReducer;
