import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  pageIndex: 0,
  pageSize: 10,
  totalFilteredRecords: 0,
  totalRecords: 0,
  results: [],
  search: {
    users: [],
    funderId: '',
    funderName: '',
  },
  orderBy: {
    field: 'lastModifiedAt',
    order: 'desc',
  },
};

const preStagingReviewSlice = createSlice({
  name: 'pre-staging-reviews',
  initialState,
  reducers: {
    resetPreStagingReviewsFilters(state) {
      return { ...state, ...initialState };
    },
    setPreStagingReviewPageIndex(state, { payload }) {
      return { ...state, pageIndex: payload };
    },
    setPreStagingReviewPageSize(state, { payload }) {
      return { ...state, pageSize: payload };
    },
    setPreStagingReviewsRecordCounts(state, {
      payload: {
        totalFilteredRecords,
        totalRecords,
      },
    }) {
      return {
        ...state,
        totalFilteredRecords,
        totalRecords,
      };
    },
    setPreStagingReviewsResults(state, { payload }) {
      return {
        ...state,
        results: payload,
      };
    },
    setPreStagingSearchTerms(state, { payload }) {
      const { users, funderId, funderName } = payload;
      return {
        ...state,
        search: {
          users, funderId, funderName,
        },
      };
    },
  },
});

export const {
  resetPreStagingReviewsFilters,
  setPreStagingReviewPageSize,
  setPreStagingReviewPageIndex,
  setPreStagingReviewsRecordCounts,
  setPreStagingReviewsResults,
  setPreStagingSearchTerms,
} = preStagingReviewSlice.actions;
const preStagingReviewReducer = preStagingReviewSlice.reducer;
export default preStagingReviewReducer;
