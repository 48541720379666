import { CircularProgress } from '@mui/material';
import Box from '@mui/material/Box';
import React, { PropsWithChildren, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { useLazyProfileQuery } from '../api/generated';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { logout } from '../store/reducers/auth/auth-reducers';
import { setIsLoading } from '../store/reducers/global-app/global-app.reducer';
import mixPanel from '../third-party/mixpanel';
import { check } from './Can';
import ErrorTempAccessAccount from '../pages/data-validation/ErrorTempAccessAccount';

function AuthProvider(props: PropsWithChildren<any>) {
  const {
    isAuthenticated, isValid, tempFunderId, profile,
  } = useAppSelector((state) => state.auth);
  const { children } = props;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [executeGetProfile, profileResult] = useLazyProfileQuery();
  const [status, setStatus] = useState('uninitialized');

  useEffect(() => {
    if (!isAuthenticated && !isValid) {
      Sentry.configureScope((scope) => scope.setUser(null));
      navigate('/login');
    } else {
      Sentry.setUser({
        email: profile?.email || 'guest@mail.com',
        username: profile?.displayName || 'Guest',
      });
      mixPanel.identity(profile);
      mixPanel.setUser(profile);
    }
  }, [isAuthenticated]);
  useEffect(() => {
    setStatus(profileResult.status);
    if (profileResult.status === 'rejected' && profileResult.error.message.includes('Unauthorized')) {
      dispatch(setIsLoading(false));
      dispatch(logout());
      window.location.href = '/login'
      // navigate('/login');
    }
  }, [profileResult]);
  useEffect(() => {
    const getProfile = async () => {
      await executeGetProfile();
    };
    getProfile().then();
  }, []);

  if (check(['temp-manager'])) {
    return (
      <ErrorTempAccessAccount
        tempFunderId={tempFunderId}
      />
    );
  }

  if (status === 'uninitialized' || status === 'pending') {
    return (
      <Box sx={{ m: 4 }}>
        <CircularProgress />
      </Box>
    );
  }
  return (
    <div className="authenticated">
      {status !== 'uninitialized' && status !== 'pending' ? children : null}
    </div>
  );
}

export default AuthProvider;
