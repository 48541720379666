import '@fontsource/roboto';
import { Alert, Snackbar } from '@mui/material';
import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useRoutes } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import Main from './components/Main';
import routeDetails from './routes/route-details';
import { useAppSelector } from './store/hooks';
import { AlertDetails, removeAlert } from './store/reducers/global-app/global-app.reducer';
import mixPanel from './third-party/mixpanel';
import { mixPanelConfig } from './utils/config';

function AlertItem({ a, sx }) {
  const dispatch = useDispatch();
  const targetRef = useRef(null);

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
    alert?: AlertDetails,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(removeAlert(alert));
  };
  return (
    <Snackbar
      open
      autoHideDuration={a.duration}
      onClose={(e, r) => handleClose(e, r, a)}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      sx={{ ...sx, width: '95%', pt: 6 }}
      ref={targetRef}
    >
      <Alert
        key={uuid()}
        variant="filled"
        severity={a.severity}
        sx={{ width: '100%' }}
        onClose={(e) => handleClose(e, null, a)}

      >
        {a.message}
      </Alert>
    </Snackbar>
  );
}

function App() {
  const content = useRoutes(routeDetails);
  const { alerts } = useAppSelector((state) => state.globalApp);
  useEffect(() => {
    mixPanel.initialise(mixPanelConfig);
  }, []);

  return (
    <Main>
      {content}
      {alerts.length > 0
      && <AlertItem key={uuid()} a={alerts[0]} sx={{}} />}
    </Main>
  );
}

export default App;
