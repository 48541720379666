import { loadStripe } from '@stripe/stripe-js';

const stripe = loadStripe(
  process.env.REACT_APP_STRIPE_SECRET_KEY_LIVE
  ?? process.env.REACT_APP_STRIPE_SECRET_KEY,
  {
    apiVersion: '2022-08-01',
  },
);

export default stripe;
