import { v4 as uuid } from 'uuid';
import {
  api as generatedApi,
  UsersDocument, UsersQuery, UsersQueryVariables,
} from './generated';

const Tag = 'Profile';

export const api = generatedApi.enhanceEndpoints({
  addTagTypes: [Tag, 'User', 'Role'],
  endpoints: {
    profile: {
      providesTags: (result, error, arg: any) => [{ type: Tag, id: arg?.id || uuid() }],
    },
    Users: {
      providesTags: (result, error, arg: any) => [{ type: 'User', id: arg.id || uuid() }],
    },
    Roles: {
      providesTags: (result, error, arg: any) => [{ type: 'Role', id: arg?.id || uuid() }],
    },
    UsersAssignRoles: {
      invalidatesTags: (result, error, arg: any) => [{ type: 'User', id: arg.id }],
    },
  },
});

export const extendedApiSlice = api.injectEndpoints({
  endpoints: (build) => ({
    Users: build.query<UsersQuery, UsersQueryVariables>({
      query: (variables) => ({ document: UsersDocument, variables }),
    }),
  }),
  overrideExisting: true,
});

export const {
  useProfileQuery,
  useLazyProfileQuery,
  useForgottenPasswordMutation,
  useResetForgottenPasswordMutation,
  useUsersInviteAcceptMutation,
  useUsersDeleteMutation,
  useLazyUsersQuery,
  useTokenRefreshMutation,
  useLazyRolesQuery,
  useUsersAssignRolesMutation,
} = api;
