import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  CardActions,
  CardContent, CardHeader, Divider, Link, Typography, useTheme,
} from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container/Container';
import Grid from '@mui/material/Grid';
import { debounce } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import PasswordStrengthBar from 'react-password-strength-bar';
import { Link as RouterLink, useNavigate, useSearchParams } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import * as Yup from 'yup';

import { useTokenMutation } from '../../../api/api.activities';
import { TokenMutationVariables, useRegisterMutation } from '../../../api/generated';
import ErrorPanel from '../../../components/common/display-panels/ErrorPanel';
import FormInputRadio from '../../../components/common/forms/FormInputRadio';
import PasswordControl from '../../../components/common/forms/PasswordControl';
import StringControl from '../../../components/common/forms/StringControl';
import FullLogo from '../../../components/common/logos/FullLogo';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { login } from '../../../store/reducers/auth/auth-reducers';
import { setIsLoading } from '../../../store/reducers/global-app/global-app.reducer';
import mixPanel from '../../../third-party/mixpanel';

const userRegistrationSchema = Yup
  .object()
  .shape({
    email: Yup
      .string()
      .email('Please enter a valid email')
      .max(255)
      .required('An email address must be entered'),
    password: Yup
      .string()
      .required('Please enter a password'),
    firstName: Yup
      .string()
      .max(255)
      .required('Please enter a Firstname'),
    lastName: Yup
      .string()
      .max(255)
      .required('Please enter a Lastname'),
  });

const accountTypeOptions = [
  {
    label: 'Funding as an individual',
    value: 'Individual',
  },
  {
    label: 'Funding as part of an organisation',
    value: 'Organisation',
  },
];

function AuthRegistrationUserDetails() {
  const { isLoading } = useAppSelector((state) => state.globalApp);
  const [executeRegister] = useRegisterMutation();
  const [token] = useTokenMutation();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [source, setSource] = useState(null);
  const [campaign, setCampaign] = useState(null);
  const [medium, setMedium] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const sourceParam = searchParams.get('utm_source');
    const mediumParam = searchParams.get('utm_medium');
    const campaignParam = searchParams.get('utm_campaign');
    setSource(sourceParam);
    setMedium(mediumParam);
    setCampaign(campaignParam);
    mixPanel.track('User Registration', {
      source: sourceParam,
      medium: mediumParam,
      campaign: campaignParam,
      status: 'start',
    });
  }, [searchParams]);

  const {
    clearErrors, control, formState, getValues, handleSubmit, register, setError, watch,
  } = useForm({
    resolver: yupResolver(userRegistrationSchema),
    mode: 'all',
    // delayError: 400,
    defaultValues: {
      registrationId: uuid(),
      email: '',
      firstName: '',
      lastName: '',
      password: '',
      accountType: 'Individual',
    },
  });
  const {
    isValid, dirtyFields,
  } = formState;

  const registerUser = async (data) => {
    const {
      email,
      firstName,
      lastName,
      password,
      mobileNumber,
      organisation,
    } = data;
    const response = await executeRegister({
      registerRegistrationData: {
        email,
        firstName,
        lastName,
        password,
        mobileNumber,
        organisation: organisation?.name ? organisation : undefined,
      },
    });
    if (!(response as any).error) {
      const { data: responseData } = (response as any);
      // get ready to do token once this is completed later.
      const { code, uic } = responseData.register;
      return { code, uic };
    }
    let message = 'An unknown error occurred please contact support@fundify.org.uk';
    if ((response as any)?.error.message?.startsWith('Conflict:')) {
      setError('email', { type: 'conflict', message: 'There is an issue with this email' });
      message = 'There was a problem creating an account with that email. It may already exist or it may be blocked ';
    }
    setErrorMessage(message);
    return null;
  };

  const requestAuthToken = async (params: { uic: string, code: string }) => {
    const { uic, code } = params;

    const authTokenResponse = await token({
      tokenTokenRequest: {
        uic,
        code,
      },
    } as TokenMutationVariables);
    if (!(authTokenResponse as any).errors) {
      return authTokenResponse as any;
    }
    setErrorMessage('Unable to authenticate the user');
    return null;
  };

  const onSubmit = async (data) => {
    setErrorMessage(null);
    dispatch(setIsLoading(true));
    const signUp = await registerUser(data);

    if (!signUp.code || !signUp.uic) {
      setErrorMessage('There was an issue registering the user.');
      dispatch(setIsLoading(false));
    } else {
      try {
        const { code, uic } = signUp;
        const signIn = await requestAuthToken({ code, uic });
        const { accessToken, profile } = signIn.data.token;
        dispatch(login({ accessToken, profile, isAuthenticate: true }));
        mixPanel.track('User Registration', {
          source,
          medium,
          campaign,
          status: 'complete',
        });
        navigate('/payment/plans');
      } catch (e) {
        setErrorMessage(e.message || 'There was an issue creating the user');
        dispatch(setIsLoading(false));
      } finally {
        dispatch(setIsLoading(false));
      }
    }
  };

  const handlePasswordChange = (score) => {
    if (dirtyFields.password) {
      if (score <= 2) {
        setError('password', { type: 'weak', message: 'Password is Weak' });
      } else {
        clearErrors('password');
      }
    }
  };
  const debouncedPasswordChangeHandler = useMemo(
    () => debounce(handlePasswordChange, 300),
    [],
  );

  useEffect(() => {
    dispatch(setIsLoading(false));
  }, []);

  return (
    <Container maxWidth={false} className="register-wizard" sx={{ mb: 8, pb: 12 }}>
      <Grid
        container
        justifyContent="center"
      >
        <Grid
          item
          xs={12}
          md={10}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              mb: 3,
            }}
          >
            <FullLogo
              color={theme.palette.primary.main}
              sx={{ width: '200px', height: '80px' }}
            />
          </Box>
          <form id="user-details-form" onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <Card>
              <CardHeader title="Sign Up" />
              <Divider />
              <CardContent>
                <input
                  name="registrationId"
                  type="hidden"
                  {...register('registrationId')}
                />
                {
                  (errorMessage) && (
                    <ErrorPanel
                      title="Error!"
                      sx={{ mb: 2 }}
                    >
                      <Typography variant="subtitle1">{errorMessage}</Typography>
                    </ErrorPanel>
                  )
                }
                <Typography variant="body1" sx={{ mt: 1, mb: 2 }}>
                  Please enter the user details that will be used to login to the account
                </Typography>
                <StringControl
                  control={control}
                  label="First name"
                  placeholder="Please enter your first name"
                  propName="firstName"
                />

                <StringControl
                  control={control}
                  label="Last name"
                  placeholder="Please enter your first name"
                  propName="lastName"
                />

                <StringControl
                  control={control}
                  label="Email"
                  placeholder="Enter an email address"
                  propName="email"
                  required
                />

                <StringControl
                  control={control}
                  label="Mobile Number"
                  placeholder="Enter a contact phone number"
                  propName="mobileNumber"
                />

                <PasswordControl control={control} autoComplete="new-password" allowInfoDisplay />

                <PasswordStrengthBar
                  onChangeScore={debouncedPasswordChangeHandler}
                  password={watch('password')}
                />

              </CardContent>
            </Card>
            <Card sx={{ mt: 4 }}>
              <CardHeader title="Account Details" />
              <Divider />
              <CardContent>
                <Typography variant="subtitle1">What type of funding will you be using Fundify to find?</Typography>
                <FormInputRadio
                  options={accountTypeOptions}
                  name="accountType"
                  control={control}
                  label="Type of account"
                />

                {getValues('accountType') === 'Organisation' && (
                  <Box sx={{ pt: 2 }}>
                    <StringControl
                      control={control}
                      label="Organisation Name"
                      placeholder="Enter the name of the organisation."
                      propName="organisation.name"
                    />
                  </Box>
                )}
              </CardContent>
            </Card>
            <Card sx={{ mt: 4 }}>
              <CardHeader title="Agreement" />
              <Divider />
              <CardContent>
                <Typography variant="body2">
                  By continuing to create an account you are agreeing to the Fundify
                  {' '}
                  <Link
                    rel="noreferrer"
                    target="_blank"
                    href="https://wearefundify.co.uk/terms"
                  >
                    terms of service
                  </Link>
                </Typography>
              </CardContent>
              <CardActions>
                <LoadingButton
                  color="primary"
                  disabled={!isValid}
                  form="user-details-form"
                  loading={isLoading}
                  type="submit"
                  variant="contained"
                  fullWidth
                >
                  Next Step
                </LoadingButton>
              </CardActions>
            </Card>
            <Typography color={theme.palette.text.secondary} variant="body1" align="center">
              Already have an account
              {' '}
              <Link component={RouterLink} to="/login">Sign in</Link>
            </Typography>
          </form>
        </Grid>
      </Grid>
    </Container>
  );
}

export default AuthRegistrationUserDetails;
