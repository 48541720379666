import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  howOptions: [],
  localAuthorityOptions: [],
  whoOptions: [],
  whatOptions: [],
  regionOptions: [],
  sourceOptions: [],
  selectedSources: [],
  selectedHows: [],
  selectedWhos: [],
  selectedWhats: [],
  selectedLocalAuthorities: [],
  selectedRegions: [],
  searchTermName: undefined,
  searchTermDescription: undefined,
  isLoading: true,
};

const funderSearchSlice = createSlice({
  name: 'funder-search',
  initialState,
  reducers: {
    resetCriteria(state) {
      return {
        ...state,
        searchTermName: '',
        selectedSources: [],
        selectedHows: [],
        selectedWhos: [],
        selectedWhats: [],
        selectedLocalAuthorities: [],
        selectedRegions: [],
      };
    },
    setSelectedHows(state, action) {
      return { ...state, selectedHows: action.payload };
    },
    setSelectedWhats(state, action) {
      return { ...state, selectedWhats: action.payload };
    },
    setSelectedWhos(state, action) {
      return { ...state, selectedWhos: action.payload };
    },
    setSelectedSources(state, action) {
      return { ...state, selectedSources: action.payload };
    },
    setSelectedLocalAuthorities(state, action) {
      return { ...state, selectedLocalAuthorities: action.payload };
    },
    setSelectedRegions(state, action) {
      return { ...state, selectedRegions: action.payload };
    },
    setOptions(state, action) {
      return { ...state, ...action.payload };
    },
    setIsLoading(state, action) {
      return { ...state, isLoading: action.payload }
    },
    setSearchTermName(state, action) {
      return { ...state, searchTermName: action.payload }
    },
    setSearchTermDescription(state, action) {
      return { ...state, searchTermDescription: action.payload }
    },
    setResults(state, action) {
      const results = action.payload.map((r) => ({
        id: r.id.raw,
        name: r.name.raw,
        source: r.source.raw,
        description: r.description.raw,
        objectives: r.objectives.raw,
        expenditure: r.expenditure.raw,
        registration_date: r.registration_date.raw,
        known_as: r.known_as.raw,
        income: r.income.raw,
        regions: r.regions.raw,
        who: r.who.raw,
        what: r.what.raw,
        how: r.how.raw,
        local_authorities: r.local_authorities.raw,
        setSelectedRegions: r.regions.raw,
      }));
      return { ...state, results };
    },
  },
});

export const {
  resetCriteria,
  setOptions,
  setResults,
  setSelectedHows,
  setSelectedWhats,
  setSelectedWhos,
  setSelectedSources,
  setSelectedLocalAuthorities,
  setSelectedRegions,
  setSearchTermName,
  setSearchTermDescription,
  setIsLoading,
} = funderSearchSlice.actions;
const funderSearchReducer = funderSearchSlice.reducer;
export default funderSearchReducer;
