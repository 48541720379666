import {
  Box,
  Button,
  CardContent,
  CardHeader,
  Chip,
  CircularProgress,
  Container,
  Divider,
  Grid, Link,
  Typography,
} from '@mui/material';
import Card from '@mui/material/Card';
import React, { useEffect } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { useNavigate, useParams } from 'react-router-dom';
import ErrorPanel from '../../components/common/display-panels/ErrorPanel';
import ArrowLeft from '../../components/icons/ArrowLeft';
import { useAppDispatch } from '../../store/hooks';
import { setIsLoading } from '../../store/reducers/global-app/global-app.reducer';
import { toPrettyFormat } from '../../utils/date-tools';
import { useFindFundDetailsQuery } from './api.funds-by-date';

function GrantDetails() {
  const { grantId } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { data, isError, isLoading } = useFindFundDetailsQuery({
    fundId: {
      grantId,
    },
  });
  const navigateBack = () => {
    navigate('/search/by-date');
  };

  useEffect(() => {
    if (data?.findFundDetails) {
      dispatch(setIsLoading(false));
    }
  }, [data]);

  const getApplicationTypeLabel = (applicationType: string) => {
    switch (applicationType) {
      case 'apply-online':
        return 'Online';
      case 'via-contact':
        return 'Via A Charity Contact';
      case 'via-form':
        return 'From';
      case 'other':
        return 'Other';
      default:
        return 'Unknown';
    }
  };

  if (isError) {
    return (
      <ErrorPanel
        title="Server Error!!"
      >
        <Typography>There was an error loading the grant details</Typography>
      </ErrorPanel>
    );
  }
  if (isLoading) {
    return (<CircularProgress />);
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return (
    <Container maxWidth={false} sx={{ pt: 1 }}>
      <Box
        sx={{
          mb: 2,
          mt: 0,
          textAlign: 'left',
          width: '100%',
        }}
      >
        <Button
          startIcon={<ArrowLeft fontSize="small" />}
          size="small"
          onClick={navigateBack}
        >
          &nbsp; Back
        </Button>
      </Box>
      {data?.findFundDetails
        && (
          <Box sx={{ pb: 14 }}>
            <Typography
              component="h1"
              align="center"
              variant="h3"
              sx={{ mb: 2 }}
            >
              {data?.findFundDetails?.funderName}
            </Typography>
            <Typography
              component="h3"
              align="center"
              variant="body1"
              sx={{ mb: 2 }}
            >
              {data?.findFundDetails?.funderId}
            </Typography>
            {data?.findFundDetails?.url
                && (
                <Link
                  rel="noreferrer"
                  target="_blank"
                  href={data?.findFundDetails?.url}
                  sx={{ width: '100%', textAlign: 'centre' }}
                >
                  <Typography
                    component="h3"
                    align="center"
                    variant="body1"
                    sx={{ mb: 2 }}
                  >
                    Visit the Site
                  </Typography>
                </Link>
                )}
            <Card
              variant="outlined"
            >
              <CardHeader title={data?.findFundDetails.name} />
              <Divider />
              <CardContent>
                <Typography paragraph variant="h6">Overview</Typography>
                <Typography paragraph variant="body1">{data?.findFundDetails.overview}</Typography>

                <Typography paragraph variant="h6">Application Details</Typography>
                <Typography paragraph variant="body1">
                  {ReactHtmlParser(data?.findFundDetails.applicationDetails)}
                </Typography>

                <Typography paragraph variant="subtitle1">
                  Apply via:
                  {' '}
                  {getApplicationTypeLabel(data?.findFundDetails?.applicationType)}
                </Typography>

                <Typography paragraph variant="h6">Exclusion Criteria</Typography>
                <Typography paragraph variant="body1">
                  {ReactHtmlParser(data?.findFundDetails.exclusionCriteria)}
                </Typography>

                <Typography variant="h6">Dates</Typography>
                {!data?.findFundDetails.dates && (
                  <Typography variant="subtitle2">
                    No Dates Available!!
                  </Typography>
                )}
                {data?.findFundDetails.dates.map((date) => (
                  <Grid container spacing={4}>
                    <Grid item sx={{ display: 'flex' }}>
                      <Typography variant="subtitle1" sx={{ mr: 2 }}>
                        Closes on:
                      </Typography>
                      <Typography color="textSecondary" variant="subtitle1">
                        {toPrettyFormat(date.closesOn)}
                      </Typography>
                    </Grid>
                    <Grid item sx={{ display: 'flex' }}>
                      <Typography variant="subtitle1" sx={{ mr: 2 }}>
                        Open From:
                      </Typography>
                      <Typography color="textSecondary" variant="subtitle1">
                        {toPrettyFormat(date.opensOn)}
                      </Typography>
                    </Grid>

                  </Grid>
                ))}
                <Typography variant="h6" sx={{ mt: 2, mb: 2 }}>Provides</Typography>
                {(data?.findFundDetails?.provides || []).map((provide) => (
                  <Chip
                    label={provide}
                    color="primary"
                    variant="outlined"
                    sx={{ mt: 1, mr: 1 }}
                  />
                ))}
                <Typography variant="h6" sx={{ mt: 2, mb: 2 }}>Themes</Typography>
                {(data?.findFundDetails?.themes || []).map((theme) => (
                  <Chip
                    label={theme}
                    color="primary"
                    variant="outlined"
                    sx={{ mt: 1, mr: 1 }}
                  />
                ))}
                <Typography variant="h6" sx={{ mt: 2, mb: 2 }}>Areas</Typography>
                {(data?.findFundDetails?.aoo || []).map((keyword) => (
                  <Chip
                    label={keyword}
                    color="primary"
                    variant="outlined"
                    sx={{ mt: 1, mr: 1 }}
                  />
                ))}
                <Typography variant="h6" sx={{ mt: 2, mb: 2 }}>Beneficiaries</Typography>
                {(data?.findFundDetails?.beneficiaries || []).map((keyword) => (
                  <Chip
                    label={keyword}
                    color="primary"
                    variant="outlined"
                    sx={{ mt: 1, mr: 1 }}
                  />
                ))}
                <Typography variant="h6" sx={{ mt: 2, mb: 2 }}>Keywords</Typography>
                {(data?.findFundDetails?.keywords || []).map((keyword) => (
                  <Chip
                    label={keyword}
                    color="primary"
                    variant="outlined"
                    sx={{ mt: 1, mr: 1 }}
                  />
                ))}
              </CardContent>
            </Card>
          </Box>
        )}
    </Container>
  );
}

export default GrantDetails;
