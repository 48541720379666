import { AppBar, Box, Toolbar } from '@mui/material';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import HeaderAvatar from 'src/components/common/header/HeaderAvatar';
import { logout } from 'src/store/reducers/auth/auth-reducers';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import mixPanel from '../../../third-party/mixpanel';
import HeaderLogo from './HeaderLogo';

function Header() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { profile } = useAppSelector((state) => state.auth);

  const onSignOut = () => {
    mixPanel.track('logout', {
      id: profile?.id,
    })
    dispatch(logout());
    navigate('/login');
  };
  const onProfileClicked = () => {
    navigate('/profile');
  };
  return (
    <AppBar sx={{ height: '64px' }} color="primary">
      <Toolbar>
        <Box sx={{ flex: '1' }} />

        <Link to="/">
          <HeaderLogo />
        </Link>

        <Box sx={{ flex: '1' }} />
        <HeaderAvatar
          displayName={profile?.displayName || ''}
          onLogoutItemClicked={onSignOut}
          onProfileItemClicked={onProfileClicked}
        />
      </Toolbar>
    </AppBar>
  );
}

export default Header;
