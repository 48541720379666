// import { styled } from '@mui/material/styles';
import React, { forwardRef } from 'react';
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import { styled } from '@mui/material/styles';

const ScrollbarRoot = styled(SimpleBar)``;

// const Scrollbar = (props) => {
//   const { children, ...rest } = props;
//   return (
//     <SimpleBar {...rest}>
//       {children}
//     </SimpleBar>
//   );
// };

const Scrollbar = forwardRef((props: any, ref: any) => (
  <ScrollbarRoot
    ref={ref}
    {...props}
  />
));
export default Scrollbar;
