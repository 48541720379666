import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  currentlyViewing: null,
  recentlyViewed: [],
};

const funderSlice = createSlice({
  name: 'funder',
  initialState,
  reducers: {
    setCurrentlyViewingFunder(state, action) {
      const { funder } = action.payload;
      if (!funder) return { ...state, currentlyViewing: null };
      const { recentlyViewed } = state;
      const recentViews = [...recentlyViewed];
      const existingRecord = recentViews.filter((ex) => ex.id === funder.id);
      const currentIndex = recentViews.indexOf(existingRecord[0]);
      if (currentIndex >= 0) {
        recentViews.splice(currentIndex, 1);
        recentViews.unshift({
          id: funder.id, name: funder.name, lastViewed: new Date().toISOString(),
        });
      } else {
        recentViews.unshift({
          id: funder.id, name: funder.name, lastViewed: new Date().toISOString(),
        });
      }

      if (recentViews.length >= 5) recentViews.pop();

      return { recentlyViewed: recentViews, currentlyViewing: funder };
    },
    clearRecentlyViewed(state) {
      return { ...state, recentlyViewed: [] };
    },
    removeRecentlyViewedItem(state, action) {
      const { funder } = action.payload;
      const { recentlyViewed } = state;
      const recentViews = [...recentlyViewed];
      const existingRecord = recentViews.filter((ex) => ex.id === funder.id);
      const currentIndex = recentViews.indexOf(existingRecord[0]);
      recentViews.splice(currentIndex, 1);
      return { ...state, recentlyViewed: recentViews };
    },
  },
});

export const {
  setCurrentlyViewingFunder, clearRecentlyViewed, removeRecentlyViewedItem,
} = funderSlice.actions;
const funderReducer = funderSlice.reducer;
export default funderReducer;
