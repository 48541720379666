import React, { lazy } from 'react';
import { Outlet } from 'react-router-dom';
import AuthProvider from '../auth/AuthProvider';
import { Can } from '../auth/Can';
import Header from '../components/common/header/Header';
import Loadable from '../components/common/loading/Loadable';

const DataCleanseGrants = Loadable(lazy(() => import('../pages/cleanse/DataCleanseGrants')));
const StagingCleanseEditFund = Loadable(lazy(() => import('../pages/cleanse/DataCleanseGrantsEdit')));

const dataCleanseRouteDetails = [
  {
    path: '/data-cleanse',
    element: (
      <AuthProvider>
        <Can
          rules={['Admin', 'Researcher']}
          yes={() => (
            <div>
              <Header />
              <Outlet />
            </div>
          )}
          no={() => {
            window.location.assign('/');
          }}
        />
      </AuthProvider>),
    children: [
      {
        path: 'grants/:stagingFundId/edit',
        element: <StagingCleanseEditFund />,
      },
      {
        path: 'grants/:stagingFundId/edit/:tab',
        element: <StagingCleanseEditFund />,
      },
      {
        path: 'grants',
        element: <DataCleanseGrants />,
      },
    ],
  },
];

export default dataCleanseRouteDetails;
