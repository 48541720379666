import { combineReducers } from '@reduxjs/toolkit';
import { api } from 'src/api/generated';
import activitiesReducer from './reducers/activities/activities-reducer';
import authReducer from './reducers/auth/auth-reducers';
import darkModeReducer from './reducers/dark-mode/reducer';
import cleanseGrantsReducer from './reducers/data-cleanse/grants-reducer';
import dataDialogHandlerReducer from './reducers/data-validation/data-dialog-handler.reducer';
import dataValidationReducer from './reducers/data-validation/data-validation.reducer';
import funderReducer from './reducers/funders/funder-reducer';
import funderSearchReducer from './reducers/funders/funder-search-reducer';
import globalAppReducer from './reducers/global-app/global-app.reducer';
import grantsByDateReducer from './reducers/grant-by-date-reducer';
import grantSearchReducer from './reducers/grant-search-reducer';
import organisationsReducer from './reducers/organisations.reducer';
import paymentsReducer from './reducers/payments.reducer';
import preStagingFunderFormReducer from './reducers/pre-staging-funder-form.reducer';
import preStagingReviewReducer from './reducers/pre-staging-review.reducer';
import stagingSearchReducer from './reducers/staging/staging-reducer';
import usersReducer from './reducers/users.reducer';

const rootReducer = combineReducers({
  activities: activitiesReducer,
  darkMode: darkModeReducer,
  auth: authReducer,
  funder: funderReducer,
  funderSearch: funderSearchReducer,
  globalApp: globalAppReducer,
  dataValidation: dataValidationReducer,
  dataDialogHandler: dataDialogHandlerReducer,
  stagingSearch: stagingSearchReducer,
  dataCleanseGrants: cleanseGrantsReducer,
  organisations: organisationsReducer,
  users: usersReducer,
  preStagingReviews: preStagingReviewReducer,
  preStagingFunderForm: preStagingFunderFormReducer,
  grantSearch: grantSearchReducer,
  payments: paymentsReducer,
  grantsByDate: grantsByDateReducer,
  [api.reducerPath]: api.reducer,
});

export default rootReducer;
