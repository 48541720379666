import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  searchTerm: '',
  results: [],
  selectedAreas: [],
  selectedBeneficiaries: [],
  selectedDocTypes: [],
  selectedCountries: [],
  selectedRegions: [],
  selectedProvides: [],
  selectedThemes: [],
  areaOptions: [],
  docTypeOptions: [],
  countryOptions: [],
  regionOptions: [],
  themeOptions: [],
  beneficiariesOptions: [],
  providesOptions: [],
  isInitialised: false,
  showFilters: true,
  current: 1,
  size: 10,
  pageMeta: {
    totalPages: 0,
    totalResults: 0,
  },
  openFilters: [],
};

const grantsSearchSlice = createSlice({
  name: 'grants-search',
  initialState,
  reducers: {
    resetGrantSearchFilters(state) {
      return { ...state, ...initialState };
    },
    setSearchTerm(state, { payload }) {
      return {
        ...state,
        searchTerm: payload,
      };
    },
    setGrantResults(state, { payload }) {
      const results = payload.map((grantRaw) => {
        const {
          id,
          doc_type: docType,
          funder_id: funderId,
          description,
          fund_program: fundProgram,
          name,
          overview,
          max_amount: maxAmount,
          min_amount: minAmount,
          link_to_funder_details: linkToFunderDetails,
          research_status: researchStatus,
          source,
          original_reference: originalReference,
        } = grantRaw;
        return {
          id: id.raw,
          funderId: funderId.raw,
          docType: docType.raw,
          maxAmount: maxAmount?.raw || undefined,
          minAmount: minAmount?.raw || undefined,
          originalReference: originalReference?.raw || undefined,
          source: source?.raw || undefined,
          linkToFunderDetails: linkToFunderDetails.raw,
          researchStatus: researchStatus?.raw || 'incomplete',
          overview: overview ? (overview?.snippet ? overview.snippet : overview.raw)
            .replace('&amp;', '&')
            .replace('&#39;', '') : null,
          description: description ? (description?.snippet ? description.snippet : description.raw)
            .replace('&amp;', '&')
            .replace('&#39;', '') : null,
          name: name ? (name?.snippet ? name.snippet : name.raw)
            .replace('&amp;', '&')
            .replace('&#39;', '') : null,
          fundProgram: fundProgram ? (fundProgram?.snippet ? fundProgram.snippet : fundProgram.raw)
            .replace('&amp;', '&')
            .replace('&#39;', '') : null,
        };
      });

      return { ...state, results, isInitialised: true };
    },
    setPage(state, { payload }) {
      const { current, size } = payload;
      return {
        ...state,
        current,
        size,
      };
    },
    setPageMeta(state, { payload }) {
      const {
        total_pages: totalPages = 0, total_results: totalResults = 0,
      } = payload;
      return {
        ...state,
        pageMeta: {
          totalPages,
          totalResults,
        },
      };
    },
    setSelectedDocTypes(state, { payload }) {
      return { ...state, selectedDocTypes: payload };
    },
    setSelectedCountries(state, { payload }) {
      return { ...state, selectedCountries: payload };
    },
    setSelectedRegions(state, { payload }) {
      return { ...state, selectedRegions: payload };
    },
    setSelectedAreas(state, { payload }) {
      return { ...state, selectedAreas: payload };
    },
    setSelectedThemes(state, { payload }) {
      return { ...state, selectedThemes: payload };
    },
    setSelectedBeneficiaries(state, { payload }) {
      return { ...state, selectedBeneficiaries: payload };
    },
    setSelectedProvides(state, { payload }) {
      return { ...state, selectedProvides: payload };
    },
    setFacetOptions(state, { payload }) {
      const {
        docTypeOptions, countryOptions, regionOptions, areaOptions, themeOptions,
        beneficiariesOptions, providesOptions,
      } = payload;
      return {
        ...state,
        docTypeOptions,
        countryOptions,
        regionOptions,
        areaOptions,
        themeOptions,
        beneficiariesOptions,
        providesOptions,
      };
    },
    setShowFilters(state, { payload }) {
      return {
        ...state,
        showFilters: payload,
      };
    },
    setOpenFilters(state, { payload }) {
      return { ...state, openFilters: payload };
    },
  },
});

export const {
  setGrantResults,
  setSearchTerm,
  setPageMeta,
  setPage,
  resetGrantSearchFilters,
  setSelectedCountries,
  setSelectedDocTypes,
  setSelectedRegions,
  setSelectedAreas,
  setFacetOptions,
  setSelectedThemes,
  setSelectedBeneficiaries,
  setSelectedProvides,
  setShowFilters,
  setOpenFilters,
} = grantsSearchSlice.actions;
const grantSearchReducer = grantsSearchSlice.reducer;
export default grantSearchReducer;
