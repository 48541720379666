import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  name: '',
  activities: [],
  sources: [],
  keywords: [],
  activitiesMatch: 'some',
  keywordsMatch: 'some',
  hasEmail: false,
  hasGrants: false,
  assigned: false,
  unassigned: true,
};

const stagingSearchSlice = createSlice({
  name: 'funder-search',
  initialState,
  reducers: {
    setSearchDetails(state, { payload }) {
      return { ...state, ...payload };
    },
    resetSearchDetails() {
      return { ...initialState };
    },
  },
});

export const {
  setSearchDetails,
  resetSearchDetails,
} = stagingSearchSlice.actions;
const stagingSearchReducer = stagingSearchSlice.reducer;
export default stagingSearchReducer;
