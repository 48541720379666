import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  missingItemsValue: [],
  filterItems: [],
  searchTerm: '',
  searchFunderNameTerm: '',
}

const cleanseGrantSlice = createSlice({
  name: 'dataCleanseGrants',
  initialState,
  reducers: {
    setMissingItemsValue(state, { payload }) {
      return { ...state, missingItemsValue: payload }
    },
    setFilterItems(state, { payload }) {
      return { ...state, filterItems: payload }
    },
    setSearchTerm(state, { payload }) {
      return { ...state, searchTerm: payload }
    },
    setSearchFunderName(state, { payload }) {
      return { ...state, searchFunderNameTerm: payload }
    },
    reset() {
      return { ...initialState }
    },
  },
})

export const {
  setMissingItemsValue,
  setSearchTerm,
  setFilterItems,
  setSearchFunderName,
  reset,
} = cleanseGrantSlice.actions;
const cleanseGrantsReducer = cleanseGrantSlice.reducer;
export default cleanseGrantsReducer;
