import { v4 as uuid } from 'uuid';
import {
  api as generatedApi,
} from '../../api/generated';

const Tag = 'FundsByDate';

export const api = generatedApi.enhanceEndpoints({
  addTagTypes: [Tag, 'Beneficiaries', 'Fund', 'Themes', 'Provides', 'AreasOfOperation'],
  endpoints: {
    CurrentSubscriptionPlans: {
      providesTags: (result, error, arg: any) => [{ type: Tag, id: arg?.id || uuid() }],
    },
    FindFundDetails: {
      providesTags: (result, error, arg: any) => [{ type: 'Fund', id: arg?.id || uuid() }],
    },
    Themes: {
      providesTags: (result, error, arg: any) => [{ type: 'Themes', id: arg?.id || uuid() }],
    },
    Beneficiaries: {
      providesTags: (result, error, arg: any) => [{ type: 'Beneficiaries', id: arg?.id || uuid() }],
    },
    Provides: {
      providesTags: (result, error, arg: any) => [{ type: 'Provides', id: arg?.id || uuid() }],
    },
    AreasOfOperation: {
      providesTags: (result, error, arg: any) => [{ type: 'AreasOfOperation', id: arg?.id || uuid() }],
    },
  },
});

export const {
  useFindUpcomingFundsQuery,
  useFindFundDetailsQuery,
  useThemesQuery,
  useBeneficiariesQuery,
  useProvidesQuery,
  useAreasOfOperationQuery,
} = api;
