import { Container, Grid, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import { Elements } from '@stripe/react-stripe-js';
import React, { useEffect } from 'react';
import FullLogo from '../../../../components/common/logos/FullLogo';
import { useAppDispatch } from '../../../../store/hooks';
import { setIsLoading } from '../../../../store/reducers/global-app/global-app.reducer';
import PaymentStatus from './PaymentMethodStatus';
import Stripe from '../../../../utils/stripe';

function PaymentComplete() {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const clientSecret = new URLSearchParams(window.location.search).get(
    'payment_intent_client_secret',
  );
  useEffect(() => {
    dispatch(setIsLoading(false));
  }, []);

  return (
    <Container maxWidth={false} className="register-wizard" sx={{ mb: 8, pb: 12 }}>
      <Grid
        container
        justifyContent="center"
      >
        <Grid
          item
          xs={12}
          md={10}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              mb: 3,
            }}
          >
            <FullLogo
              color={theme.palette.primary.main}
              sx={{
                width: '200px',
                height: '80px',
              }}
            />
          </Box>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="stretch"
            sx={{ md: { minWidth: '960px' } }}
          >
            <Elements
              stripe={Stripe}
              options={{
                clientSecret,
              }}
            >
              <PaymentStatus />
            </Elements>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}

export default PaymentComplete;
