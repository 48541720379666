import React, { lazy } from 'react';
import { Outlet } from 'react-router-dom';
import { Can } from '../auth/Can';
import DataGatherHeader from '../components/common/header/DataGatherHeader';
import Loadable from '../components/common/loading/Loadable';

const DataGatherInvalidInvite = Loadable(lazy(() => import('../pages/data-validation/DataValidationInvalidInvite')));
const DataGatherFunderDetails = Loadable(lazy(() => import('../pages/data-validation/DataValidationFunderDetails')));
const DataGatherAcceptInvite = Loadable(lazy(() => import('../pages/data-validation/DataValidationAcceptInvite')));
const DataValidationSuccess = Loadable(lazy(() => import('../pages/data-validation/DataValidationSuccess')));

const dataGatherRouteDetails = [
  {
    path: '/data-validation',
    element: (
      <div>
        <DataGatherHeader />
        <Outlet />
      </div>),
    children: [
      {
        path: 'accept-invite',
        element: <DataGatherAcceptInvite />,
      },
      {
        path: 'success',
        element: <DataValidationSuccess />,
      },
      { path: 'invalid-invite', element: <DataGatherInvalidInvite /> },
      {
        path: 'funder/:funderId',
        element: (
          <Can
            rules={['temp-manager', 'Admin']}
            yes={() => (<DataGatherFunderDetails />)}
            no={() => {
              window.location.assign('/data-validation/invalid-invite');
            }}
          />
        ),
      },
    ],
  },
];

export default dataGatherRouteDetails;
