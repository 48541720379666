import {
  Box, Button, List, ListItem, Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import {
  SortOrder,
  useGetActivitiesByIdQuery,
  useGetActivitiesQuery,
  useMutateActivityMutation,
} from '../../api/generated';
import { setIsLoading } from '../../store/reducers/global-app/global-app.reducer';
import ActivitiesForm from './ActivitiesForm';

function ActivitiesEdit() {
  const { activityId = 'new' } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    control, handleSubmit, register, setValue,
  } = useForm({
    defaultValues: {
      id: activityId === 'new' ? uuid() : activityId,
      name: '',
      description: '',
      parent: null,
    },
  });

  const { data, isLoading } = useGetActivitiesByIdQuery({
    activitySearchDetails: {
      where: {
        id: {
          equals: activityId,
        },
      },
    },
  });
  const { data: activitiesData } = useGetActivitiesQuery({
    activitySearchDetails: {
      orderBy: [{
        name: SortOrder.Asc,
      }],
      where: {
        parent: null,
      },
    },
  });
  const [update, { error, isLoading: isUpdateLoading }] = useMutateActivityMutation();
  const [childrenList, setChildrenList] = useState([]);

  useEffect(() => {
    dispatch(setIsLoading(isLoading));
    const { results } = data?.getActivities || { results: null };
    if (results && results.length > 0) {
      if (results[0]) {
        const {
          id, name, description, parent, children,
        } = results[0];
        setValue('id', id);
        setValue('name', name);
        setValue('description', description);
        setValue('parent', parent);
        setChildrenList(children);
      }
    }
  }, [data, isLoading]);

  const onSubmit = async (values) => {
    const {
      id, name, description, parent,
    } = values;
    const response = await update({
      activityDetails: {
        id,
        name,
        description,
        parentId: parent?.id || null,
      },
    });
    if (!(response as any)?.errors) {
      navigate('/activities');
    }
  };
  return (
    <Box>
      <Button onClick={() => navigate('/activities')}> Back to list </Button>
      {
        (!isUpdateLoading && error) && (
          <Box>
            <code>{JSON.stringify(error)}</code>
          </Box>
        )
      }
      <ActivitiesForm
        handleSubmit={handleSubmit(onSubmit)}
        control={control}
        register={register}
        setValue={setValue}
        options={activitiesData?.getActivities?.results || []}
      />
      <Typography>Sub-Activities</Typography>
      <List>
        {childrenList.map((c) => (
          <ListItem key={c.name}>{c.name}</ListItem>
        ))}
      </List>
    </Box>
  );
}

export default ActivitiesEdit;
