import { api as generatedApi } from './generated';

export const api = generatedApi.enhanceEndpoints({
  addTagTypes: ['Activity'],
  endpoints: {
    // token: {
    //   async onQueryStarted(arg, { queryFulfilled }) {
    //     const { data } = await queryFulfilled;
    //     client.setHeader('authentication', `Bearer ${(data as any).accessToken}`);
    //   },
    // },
    GetActivities: {
      providesTags: (result, error, arg: any) => [{ type: 'Activity', id: arg.id }],
    },
    DeleteActivities: {
      invalidatesTags: (result, error, arg: any) => [{ type: 'Activity', id: arg.id }],
    },
    MutateActivity: {
      invalidatesTags: (result, error, arg: any) => [{ type: 'Activity', id: arg.id }],
    },
  },
});

export const {
  useTokenMutation, useGetActivitiesQuery, useLazyGetActivitiesQuery, useDeleteActivitiesMutation,
} = api;
