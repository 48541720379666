import {
  Autocomplete, Button, Paper, TextField, Typography, useTheme,
} from '@mui/material';
import React from 'react';
import { Controller } from 'react-hook-form';
import StringControl from '../../components/common/forms/StringControl';
import './ActivitiesForm.css';

function ActivitiesForm({
  handleSubmit, control, register, setValue, options,
}) {
  const theme = useTheme();

  return (
    <Paper
      sx={{ m: 4, p: 2, background: theme.palette.background.paper }}
    >
      <Typography component="h1" variant="h5">Activity Details</Typography>
      <form onSubmit={handleSubmit}>
        <input type="hidden" name="id" {...register('id')} />
        <StringControl propName="name" label="Name" placeholder="Enter a name" control={control} sx={{ mb: 2 }} />
        <StringControl
          propName="description"
          label="Description"
          placeholder="Enter a description"
          control={control}
          multiline
          rows={4}
          sx={{ mb: 2 }}
        />
        <Typography component="h1" variant="h5">Parent Details</Typography>
        <Typography variant="subtitle1">
          If the activity needs has a parent / is a sub category of another
          activity
        </Typography>
        <Controller
          name="parent"
          control={control}
          render={({ field: { value }, fieldState: { error } }) => (
            <Autocomplete
              id="parent"
              options={options}
              sx={{ width: '100%', mb: 2 }}
              getOptionLabel={(option: any) => `${option.name}`}
              value={value}
              onChange={(e, change) => setValue('parent', change)}
              renderInput={(params) => (
                <TextField
                  error={!!error}
                  helperText={error ? error.message : null}
                  {...params}
                  label="Parent Activity (Optional)"
                />
              )}
            />
          )}
        />

        <Button type="submit" variant="contained">Submit</Button>
      </form>
    </Paper>
  );
}

export default ActivitiesForm;
