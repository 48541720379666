import React, { lazy } from 'react';
import { Outlet } from 'react-router-dom';

import AuthProvider from 'src/auth/AuthProvider';
import { Can } from '../auth/Can';
import Header from '../components/common/header/Header';
import Loadable from '../components/common/loading/Loadable';
// import { PageNotification } from '../components/common/notifications/PageNotification';
import ActivitiesEdit from '../pages/activities/ActivitiesEdit';

import PaymentCancel from '../pages/auth/register/payment/PaymentCancel';
import PaymentError from '../pages/auth/register/payment/PaymentError';
import PaymentComplete from '../pages/auth/register/payment/PaymentComplete';
import PaymentCreate from '../pages/auth/register/payment/PaymentCreate';
import PaymentPlans from '../pages/auth/register/payment/PaymentPlans';
import AuthRegistrationUserDetails from '../pages/auth/register/AuthRegistrationUserDetails';
import FundsByDate from '../pages/funds/FundsByDate';
import GrantDetails from '../pages/funds/GrantDetails';

// import InviteAcceptForm from '../pages/auth/invite-accept/InviteAcceptForm';
import adminRouteDetails from './admin.routes';
import dataCleanseRouteDetails from './cleanse.routes';
import dataGatherRouteDetails from './data-validation.routes';
import researchRouteDetails from './research.routes';

const Home = Loadable(lazy(() => import('../pages/home/Home')));
const Login = Loadable(lazy(() => import('src/pages/auth/login/Login')));
// const Register = Loadable(lazy(() => import('src/pages/auth/register/Register')));
const Unauthorised = Loadable(lazy(() => import('src/pages/auth/unauthorised/Unauthorised')));
const Profile = Loadable(lazy(() => import('../pages/auth/profile/Profile')));
const StagingFundersList = Loadable(lazy(() => import('../pages/staging/funders/StagingFundersList')));
const SearchFunding = Loadable(lazy(() => import('../pages/search/SearchFunding')));
const GrantSearchMain = Loadable(lazy(() => import('../pages/search/GrantSearchMain')));
const SavedSearchesMain = Loadable(lazy(() => import('../pages/search/SavedSearchesMain')));
const FunderDetailsScreen = Loadable(lazy(() => import('../pages/search/funder-details/FunderDetailsScreen')));
const SearchFunderDetails = Loadable(lazy(() => import('../pages/search/funder-details/SearchFunderDetails')));
const ActivitiesList = Loadable(lazy(() => import('../pages/activities/ActivitiesList')));
const StagingFunderEdit = Loadable(lazy(() => import('../pages/staging/funders/StagingFunderEdit')));
const ForgottenPassword = Loadable(lazy(() => import('../pages/auth/forgotten-password/ForgottenPassword')));
const PasswordReset = Loadable(lazy(() => import('../pages/auth/forgotten-password/PasswordReset')));
const InviteAccept = Loadable(lazy(() => import('../pages/auth/invite-accept/InviteAccepted')));

const routeDetails = [
  {
    path: '/',
    element: (
      <AuthProvider>
        <div>
          <Header />
          <Outlet />
        </div>
      </AuthProvider>),
    children: [
      { path: '/profile', element: <Profile /> },
      { path: '/', element: <Home /> },
      { path: '/home/:tab', element: <Home /> },
    ],
  },
  {
    path: '/staging',
    element: (
      <AuthProvider>
        <Can
          rules={['Admin', 'Executive']}
          yes={() => (
            <div>
              <Header />
              <Outlet />
            </div>
          )}
          no={() => {
            window.location.assign('/');
          }}
        />
      </AuthProvider>),
    children: [
      { path: 'funders', element: <StagingFundersList /> },
      { path: 'funders/:funderId/edit', element: <StagingFunderEdit /> },
      { path: 'funders/:funderId/edit/:tab', element: <StagingFunderEdit /> },
    ],
  },
  {
    path: '/activities',
    element: (
      <AuthProvider>
        <div>
          <Header />
          <Outlet />
        </div>
      </AuthProvider>),
    children: [
      { path: '', element: <ActivitiesList /> },
      { path: ':activityId/edit', element: <ActivitiesEdit /> },
    ],
  },
  {
    path: '/search',
    element: (
      <AuthProvider>
        <div>
          <Header />
          <Outlet />
        </div>
      </AuthProvider>),
    children: [
      { path: 'by-date', element: <Can rules={['Admin', 'Subscriber']} yes={() => <FundsByDate />} /> },
      { path: 'grant/:grantId', element: <Can rules={['Admin', 'Subscriber']} yes={() => <GrantDetails />} /> },
      { path: 'saved', element: <SavedSearchesMain /> },
      { path: 'grants', element: <GrantSearchMain /> },
      { path: 'funders/:funderId/view/:tab', element: <FunderDetailsScreen /> },
      { path: 'funders', element: <SearchFunding /> },
      { path: 'funders/:funderId', element: <SearchFunderDetails /> },
    ],
  },
  {
    path: '/login',
    element: <Login />,
  },
  {
    path: '/Unauthorised',
    element: <Unauthorised />,
  },
  {
    path: '/payment/plans',
    element:
  <AuthProvider>
    <PaymentPlans />
  </AuthProvider>,
  },
  {
    path: '/payment/create',
    element: <PaymentCreate />,
  },
  {
    path: '/payment/complete',
    element: <PaymentComplete />,
  },
  {
    path: '/auth/register',
    element: <AuthRegistrationUserDetails />,
  },
  {
    path: '/payment/error',
    element: <PaymentError />,
  },
  {
    path: '/payment/canceled',
    element: <PaymentCancel />,
  },
  {
    path: '/forgotten-password',
    element: <ForgottenPassword />,
  },
  {
    path: '/password-reset',
    element: <PasswordReset />,
  },
  {
    path: '/auth/invite-accept',
    element: <InviteAccept />,
  },
  ...dataGatherRouteDetails,
  ...dataCleanseRouteDetails,
  ...adminRouteDetails,
  ...researchRouteDetails,
];

export default routeDetails;
