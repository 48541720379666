import { Alert, AlertTitle, Box } from '@mui/material';
import React from 'react';

function ErrorPanel({ children, title, ...rest }) {
  return (
    <Box id="admin-error-panel" {...rest}>
      <Alert severity="error">
        {title && <AlertTitle>{title}</AlertTitle>}
        {children}
      </Alert>
    </Box>
  );
}

export default ErrorPanel;
