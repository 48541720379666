import * as mixpanel from 'mixpanel-browser';

class MixPanel {
  TOKEN: any;

  API_URL: any;

  ENABLED: boolean;

  initialized: boolean;

  constructor() {
    this.TOKEN = null;
    this.API_URL = null;
    this.ENABLED = false;
    this.initialized = false;
  }

  configure(config) {
    this.TOKEN = config.token;
    this.API_URL = config.url;
    this.ENABLED = config.enabled;
  }

  initialise(config) {
    if (this.initialized) {
      console.warn('MixPanel can only be initialized once.');
      return;
    }
    if (!config.token) {
      console.warn('Mixpanel requires token to be loaded.');
      return;
    }
    if (!config.url) {
      console.warn('Mixpanel requires url to be loaded.');
      return;
    }
    this.configure(config);
    mixpanel.init(
      config.token,
      { host: config.url },
      '',
    );
    this.initialized = process.env.REACT_APP_MIXPANEL_ENABLED === 'true';
  }

  identity(user) {
    if (mixpanel.identify && this.initialized && this.ENABLED) {
      mixpanel.identify(user.id);
    }
  }

  setUser(user) {
    if (this.initialized && this.ENABLED) {
      mixpanel.people.set({
        $first_name: user.firstName,
        $last_name: user.lastName,
        $name: user.displayName,
        $email: user.email, // only reserved properties need the $
        'Sign up date': user.createdAt || new Date().toISOString(),
        'Most Recent Login': new Date().toISOString(),
        USER_ID: user.displayName,
      });
    }
  }

  track(name, payload) {
    if (this.initialized && this.ENABLED) {
      mixpanel.track(name, payload);
    }
  }

  pageView(name, payload = undefined) {
    if (this.initialized && this.ENABLED) {
      mixpanel.track('page_view', { name, ...payload });
    }
  }
}

const mixPanel = new MixPanel();
export default mixPanel;
