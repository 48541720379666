import { createSlice } from '@reduxjs/toolkit';
import { getBlankContact, getBlankGrant } from '../../pages/data-validation/consts/funder-default-values.const';

export const initialState = {
  grants: null,
  editGrantDialogOpen: false,
  confirmDeleteGrantDialogOpen: false,
  confirmVerifyFunderOpen: false,
};

const preStagingFunderFormSlice = createSlice({
  name: 'pre-staging-funder-handler',
  initialState,
  reducers: {
    // Contacts Handler
    setContactDialogOpen(state, { payload }) {
      return { ...state, editContactDialogOpen: payload };
    },
    setContactDeleteDialogOpen(state, { payload }) {
      return { ...state, confirmDeleteContactDialogOpen: payload };
    },
    setCurrentContact(state, { payload }) {
      return { ...state, contact: payload };
    },
    createNewContact(state, { payload }) {
      return { ...state, contact: payload };
    },
    updateContactDetails(state, { payload }) {
      return {
        ...state,
        contact: { ...payload, create: undefined },
        updatedContact: new Date().toISOString(),
        editContactDialogOpen: false,
      };
    },
    setCurrentContactIndex(state, { payload }) {
      return { ...state, currentContactIndex: payload };
    },
    checkRemoveContact(state, { payload: { contactDetails, index } }) {
      return {
        ...state,
        contact: contactDetails,
        currentContactIndex: index,
        confirmDeleteContactDialogOpen: true,
      };
    },
    addContact(state, { payload: { contactDetails, index } }) {
      return {
        ...state,
        contact: contactDetails,
        currentContactIndex: index,
        editContactDialogOpen: true,
      };
    },
    editContact(state, { payload: { contactDetails, index } }) {
      return {
        ...state,
        contact: contactDetails,
        currentContactIndex: index,
        editContactDialogOpen: true,
      };
    },
    removeContact(state) {
      return {
        ...state,
        contact: getBlankContact(),
        removedContact: new Date().toISOString(),
        confirmDeleteContactDialogOpen: false,
      };
    },
    // Grant Methods
    addGrant(state, { payload: { grantDetails = { ...getBlankGrant(), create: true }, index } }) {
      return {
        ...state,
        grant: grantDetails,
        currentGrantIndex: index,
        editGrantDialogOpen: true,
      };
    },
    editGrant(state, { payload: { grantDetails, index } }) {
      return {
        ...state,
        grant: { ...grantDetails, create: undefined },
        currentGrantIndex: index,
        editGrantDialogOpen: true,
      };
    },
    updateGrantDetails(state, { payload: { grantDetails } }) {
      return {
        ...state,
        grant: grantDetails,
        editGrantDialogOpen: false,
        updatedGrant: new Date().toISOString(),
      };
    },
    cancelGrantDialog(state, { payload }) {
      return { ...state, grant: payload, editGrantDialogOpen: false };
    },
    checkRemoveGrant(state, { payload: { grantDetails, index } }) {
      return {
        ...state,
        grant: grantDetails,
        currentGrantIndex: index,
        confirmDeleteGrantDialogOpen: true,
      };
    },
    removeGrant(state) {
      return {
        ...state,
        grant: getBlankGrant(),
        removedGrant: new Date().toISOString(),
        confirmDeleteGrantDialogOpen: false,
      };
    },
    cancelGrantDeleteDialog(state) {
      return { ...state, confirmDeleteGrantDialogOpen: false };
    },
    setConfirmVerifyFunder(state, { payload: { open, funder } }) {
      return { ...state, confirmVerifyFunderOpen: open, funder };
    },
  },
});

export const {
  addContact,
  editContact,
  addGrant,
  editGrant,
  removeGrant,
  updateGrantDetails,
  cancelGrantDialog,
  cancelGrantDeleteDialog,
  setContactDialogOpen,
  setContactDeleteDialogOpen,
  updateContactDetails,
  setCurrentContact,
  setCurrentContactIndex,
  checkRemoveGrant,
  removeContact,
  checkRemoveContact,
  setConfirmVerifyFunder,
} = preStagingFunderFormSlice.actions;
const preStagingFunderFormReducer = preStagingFunderFormSlice.reducer;
export default preStagingFunderFormReducer;
