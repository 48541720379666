import { LocalizationProvider } from '@mui/lab';
import {
  Box, Grid, LinearProgress, Link, Typography,
} from '@mui/material';
import React, { PropsWithChildren, useEffect } from 'react';
import DateAdapter from '@mui/lab/AdapterLuxon';
import { ErrorBoundary } from 'react-error-boundary'
import { check } from '../auth/Can';
import { useAppSelector } from '../store/hooks';
import mixPanel from '../third-party/mixpanel';

function ErrorFallback({ error }) {
  useEffect(() => {
    console.error(error);
  }, [])
  return (
    <Grid container direction="column" justifyContent="center" alignContent="center">
      <Grid item xs sx={{ pt: 4 }}>
        <Typography textAlign="center" variant="h4" component="h1">Unknown Error</Typography>
      </Grid>
      <Grid justifyContent="center" alignContent="center" item xs sx={{ pt: 4, textAlign: 'center' }}>
        <img
          alt="Fundify Nerd in coat rushing to the rescue"
          src={`${process.env.PUBLIC_URL}/humaaans/nerd_in_coat.svg`}
        />
      </Grid>
      <Grid item>
        <Typography variant="body1">Apologies but one of those pesky Gremlins has been at it again.</Typography>
        <Typography variant="body2" textAlign="center">
          In the mean time please contact support on &nbsp;
          <Link href="mailto:support@fundify.otg.uk">support@fundify.org.uk</Link>
        </Typography>
      </Grid>
      {check(['Admin']) && <pre>{error.message}</pre>}
    </Grid>
  );
}

function Main(props: PropsWithChildren<any>) {
  const { children } = props;
  const {
    isLoading,
  } = useAppSelector((state) => state.globalApp);
  useEffect(() => {
    mixPanel.pageView(window.location.pathname)
  }, [window.location.pathname]);
  return (
    <LocalizationProvider dateAdapter={DateAdapter}>
      <div className="main" style={{ paddingTop: '58px' }}>
        <div className="page">
          <Box sx={{ width: '100%', pt: '6px' }}>
            { isLoading && <LinearProgress color="secondary" />}
          </Box>
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            {children}
          </ErrorBoundary>
        </div>
      </div>
    </LocalizationProvider>
  );
}

export default Main;
