const inputDark = '#efefef';
const errorMain = '#f44336';
const successMain = '#4caf50';
export const successLight = 'rgba(76, 175, 80, 0.2)';
export const successDark = 'rgba(76, 175, 80, 0.63)';
export const errorLight = 'rgba(244, 67, 54, 0.2)';
export const errorDark = 'rgba(244, 67, 54, 0.63)';

export const commonColors = {
  white: '#ffffff',
  black: '#000000',
  successMain,
  errorMain,
};

export const appColors = {
  background: commonColors.white,
  font: commonColors.black,
  primary: '#FF6B35',
  orange: '#FF6B35',
  darkBlue: '#004E89',
  // darkBlue: '#253f89',
  darkBlueLight: '#4979b9',
  darkBlueDark: '#00275b',
  scooter: '#27B8CD',
};

export const lightModeColors = {
  // background: '#cfcfcf',
  background: '#E5E9EC',
  paper: '#EAFDF8',
}

export const darkModeColors = {
  font: commonColors.white,
  background: '#000000',
  inputDark,
};
