import LoadingButton from '@mui/lab/LoadingButton';
import {
  CardActions, CardContent,
  CardHeader, Divider, Typography, useTheme,
} from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import React, { useEffect, useState } from 'react';
import {
  Elements, PaymentElement, useElements, useStripe,
} from '@stripe/react-stripe-js';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import { useSubscriptionCreateMutation } from '../../../../api/generated';
import ErrorPanel from '../../../../components/common/display-panels/ErrorPanel';
import FullLogo from '../../../../components/common/logos/FullLogo';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { setIsLoading } from '../../../../store/reducers/global-app/global-app.reducer';
import { restPayment } from '../../../../store/reducers/payments.reducer';
import Stripe from '../../../../utils/stripe';

function CheckoutForm() {
  const dispatch = useAppDispatch();
  const stripe = useStripe();
  const elements = useElements();
  const [errorMessage, setErrorMessage] = useState(null);

  const onSubmit = async (event) => {
    event.preventDefault();
    dispatch(setIsLoading(true));
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${window.location.origin}/payment/complete`,
      },
    });
    if (error) {
      setErrorMessage(error.message);
    } else {
      dispatch(restPayment());
    }
    dispatch(setIsLoading(false));
  };
  return (
    <form id="payment-method-form" onSubmit={onSubmit} style={{ width: '100%' }}>
      {errorMessage && (
        <ErrorPanel title="Payment Error">
          {errorMessage}
        </ErrorPanel>
      )}
      <PaymentElement />
    </form>
  );
}

function PaymentCreate() {
  const theme = useTheme();
  const { customerId, interval } = useAppSelector((state) => state.payments);
  const [createSubscription] = useSubscriptionCreateMutation();
  const [options, setOptions] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');

  const dispatch = useAppDispatch();
  useEffect(() => {
    const createNewSubscription = async () => {
      const response = await createSubscription({
        subscriptionDetails: {
          priceId: interval,
          customerId,
        },
      });

      if ('data' in response) {
        const { clientSecret } = (response as any).data.subscriptionCreate;
        setOptions({
          clientSecret,
        });
      } else {
        setErrorMessage('There was an issue loading the subscription details');
      }
      dispatch(setIsLoading(false));
    };
    createNewSubscription().then();
  }, [customerId]);
  return (
    <Container maxWidth={false} className="register-wizard" sx={{ mb: 8, pb: 12 }}>
      <Grid
        container
        justifyContent="center"
      >
        <Grid
          item
          xs={12}
          md={10}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              mb: 3,
            }}
          >
            <FullLogo
              color={theme.palette.primary.main}
              sx={{
                width: '200px',
                height: '80px',
              }}
            />
          </Box>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="stretch"
            sx={{ md: { minWidth: '960px' } }}
          >
            <Box sx={{ p: 2 }}>
              {
                (errorMessage) && (
                  <ErrorPanel
                    title="Error!"
                    sx={{ mb: 2 }}
                  >
                    <Typography variant="subtitle1">{errorMessage}</Typography>
                  </ErrorPanel>
                )
              }
            </Box>
            <Grid direction="column" container spacing={2}>
              <Card>
                <CardHeader title="Enter payment details" />
                <Divider />
                <CardContent>
                  {options && (
                  <Elements stripe={Stripe} options={options}>
                    <CheckoutForm />
                  </Elements>
                  )}
                </CardContent>
                <CardActions>
                  <LoadingButton form="payment-method-form" variant="contained" fullWidth type="submit">Submit</LoadingButton>
                </CardActions>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}

export default PaymentCreate;
