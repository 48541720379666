import { createSlice } from '@reduxjs/toolkit';

export interface GrantByDatePageMetaProps {
  totalPages: number
  totalResults: number
}

export interface GrantByDateProps {
  pageIndex: number
  pageSize: number
  totalRecords: number
  totalFilteredRecords: number
  searchTerm?: string
  selectedThemes?: string[]
  selectedBeneficiaries?: string[]
  selectedProvides?: string[]
  selectedAreas?: string[]
  selectedCountries?: string[]
  selectedRegions?: string[]
  selectedLocalAuthorities?: string[]
  selectedMetropolitanCounties?: string[]
  pageMeta?: GrantByDatePageMetaProps
}

export const initialState: GrantByDateProps = {
  pageIndex: 0,
  pageSize: 10,
  totalRecords: 0,
  totalFilteredRecords: 0,
  searchTerm: '',
  selectedThemes: [],
  selectedBeneficiaries: [],
  selectedProvides: [],
  selectedAreas: [],
  selectedCountries: [],
  selectedRegions: [],
  selectedLocalAuthorities: [],
  selectedMetropolitanCounties: [],
  pageMeta: {
    totalPages: 0,
    totalResults: 0,
  },
};

const grantsByDateSlice = createSlice({
  name: 'grants-by-date',
  initialState,
  reducers: {
    resetGrantSearchFilters(state) {
      return { ...state, ...initialState };
    },
    setSearchTerm(state, { payload }) {
      return {
        ...state,
        searchTerm: payload,
      };
    },
    setTotalFiltered(state, { payload }) {
      return {
        ...state,
        totalRecords: payload.totalResults,
        totalFilteredRecords: payload.filteredResults,
      }
    },
    setGrantsPageSize(state, { payload }) {
      return {
        ...state,
        pageSize: payload,
      };
    },
    setGrantsByPage(state, { payload }) {
      return {
        ...state,
        pageIndex: payload,
      };
    },
    setPageMeta(state, { payload }) {
      const {
        total_pages: totalPages = 0, total_results: totalResults = 0,
      } = payload;
      return {
        ...state,
        pageMeta: {
          totalPages,
          totalResults,
        },
      };
    },
    setSelectedThemes(state, { payload }) {
      return { ...state, selectedThemes: payload };
    },
    setSelectedBeneficiaries(state, { payload }) {
      return { ...state, selectedBeneficiaries: payload };
    },
    setSelectedProvides(state, { payload }) {
      return { ...state, selectedProvides: payload };
    },
    setSelectedAreas(state, { payload }) {
      return { ...state, selectedAreas: payload };
    },
    setSelectedRegions(state, { payload }) {
      return { ...state, selectedRegions: payload };
    },
    setSelectedLocalAuthorities(state, { payload }) {
      return { ...state, selectedLocalAuthorities: payload };
    },
    setSelectedMetropolitanCounties(state, { payload }) {
      return { ...state, selectedMetropolitanCounties: payload };
    },
    setSelectedCountries(state, { payload }) {
      return { ...state, selectedCountries: payload };
    },
  },
});

export const {
  setSearchTerm,
  setPageMeta,
  setGrantsByPage,
  setTotalFiltered,
  resetGrantSearchFilters,
  setGrantsPageSize,
  setSelectedThemes,
  setSelectedBeneficiaries,
  setSelectedProvides,
  setSelectedAreas,
  setSelectedRegions,
  setSelectedLocalAuthorities,
  setSelectedMetropolitanCounties,
  setSelectedCountries,
} = grantsByDateSlice.actions;
const grantsByDateReducer = grantsByDateSlice.reducer;
export default grantsByDateReducer;
