import { Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import Alert from '@mui/material/Alert';
import Link from '@mui/material/Link';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useStripe } from '@stripe/react-stripe-js';
import { useTokenRefreshMutation } from '../../../../api/api.auth';
import { useAppDispatch } from '../../../../store/hooks';
import { login } from '../../../../store/reducers/auth/auth-reducers';
import mixPanel from '../../../../third-party/mixpanel';

function SuccessfulPayment() {
  const homeLink = '/';
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [getRefreshToken] = useTokenRefreshMutation();

  useEffect(() => {
    const updateUser = async () => {
      const accessRefresh = await getRefreshToken();
      const { data: tokenRefreshData } = (accessRefresh as any);
      const { accessToken: newAccessToken, profile } = (tokenRefreshData as any).tokenRefresh;

      dispatch(login({ accessToken: newAccessToken, profile, isAuthenticate: true }));
      mixPanel.setUser(profile);
      mixPanel.identity(profile);
      mixPanel.track('New User', {
        email: profile.email,
        id: profile.id,
      });
    }
    updateUser().then(() => {
      setTimeout(() => {
        navigate(homeLink);
      }, 3000);
    })
  }, [])
  return (
    <>
      <Alert title="Payment Status" severity="success" sx={{ width: '100%' }}>
        Payment successful.
      </Alert>
      <Typography variant="subtitle1" align="center" sx={{ width: '100%', m: 3 }}>
        The payment was successful. You will be redirected in a moment.
      </Typography>
      <Typography variant="body2" align="center" sx={{ width: '100%', m: 3 }}>
        If not
        {' '}
        <Link component={RouterLink} to="/">click here</Link>
      </Typography>
    </>
  )
}

function PendingPayment() {
  const homeLink = '/';
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      navigate(homeLink);
    }, 3000)
  }, [])
  return (
    <>
      <Alert title="Payment Status" severity="info" sx={{ width: '100%' }}>
        Payment Pending.
      </Alert>
      <Typography variant="subtitle1" align="center" sx={{ width: '100%', m: 3 }}>
        The payment is still pending, please wait a little longer.
      </Typography>
      <Typography variant="body2" align="center" sx={{ width: '100%', m: 3 }}>
        If not
        {' '}
        <Link component={RouterLink} to="/">click here</Link>
      </Typography>
    </>
  )
}

function FailedPayment() {
  const paymentLink = '/payment/create';
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      navigate(paymentLink);
    }, 3000)
  }, [])

  return (
    <>
      <Alert title="Payment Status" severity="error" sx={{ width: '100%' }}>
        Payment Failed!.
      </Alert>
      <Typography variant="subtitle1" align="center" sx={{ width: '100%', m: 3 }}>
        The payment failed. You will be redirected back to try again.
      </Typography>
      <Typography variant="body2" align="center" sx={{ width: '100%', m: 3 }}>
        If not
        {' '}
        <Link component={RouterLink} to={paymentLink}>click here</Link>
      </Typography>
    </>
  )
}

function PaymentStatus() {
  const stripe = useStripe();
  const [paymentStatus, setPaymentStatus] = useState('unknown');

  useEffect(() => {
    if (!stripe) {
      return;
    }

    // Retrieve the "payment_intent_client_secret" query parameter appended to
    // your return_url by Stripe.js
    const clientSecret = new URLSearchParams(window.location.search).get(
      'payment_intent_client_secret',
    );

    // Retrieve the PaymentIntent
    stripe
      .retrievePaymentIntent(clientSecret)
      .then(({ paymentIntent }) => {
        setPaymentStatus(paymentIntent.status);
      });
  }, [stripe]);
  return (
    <>
      {
        (() => {
          switch (paymentStatus) {
            case 'succeeded':
              return <SuccessfulPayment />
            case 'requires_payment_method':
              return <FailedPayment />
            default:
              return <PendingPayment />
          }
        })()
      }
    </>
  );
}

export default PaymentStatus;
