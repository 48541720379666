import { OutlinedInputProps, TextField } from '@mui/material';
import React from 'react';
import { Controller } from 'react-hook-form';

export interface StringControlProps {
  /**
   * The name the prop will be given aas part of the form. Ultimately used in react-form-hooks
   * @example userName
   */
  propName: string;
  label: string;
  placeholder: string;
  type?: 'text' | 'date' | 'tel' | 'url' | 'email';

  InputProps?: Partial<OutlinedInputProps>;
  margin?: 'none' | 'dense' | 'normal';
  disabled?: boolean;
  variant?: 'filled' | 'standard' | 'outlined';
  control: any;
  autoComplete?: 'on' | 'off' | 'new-password';
  multiline?: boolean;
  rows?: number;
  sx?: any;
  required?: boolean;
}

function StringControl({
  control,
  propName,
  label,
  placeholder,
  type = 'text',
  InputProps,
  margin = 'none',
  disabled = false,
  variant = 'outlined',
  autoComplete = 'on',
  multiline = false,
  rows = 0,
  required = false,
  sx = {},
}: StringControlProps) {
  return (
    <Controller
      name={propName}
      control={control}
      render={({ field: { ref, onChange, value }, fieldState: { error } }) => (
        <TextField
          id={`${propName}`}
          error={!!error}
          fullWidth
          helperText={error ? error.message : null}
          label={label}
          InputLabelProps={{ shrink: true }}
          name={propName}
          disabled={disabled}
          placeholder={placeholder || label}
          variant={variant}
          margin={margin}
          type={type || 'text'}
          autoComplete={autoComplete}
          InputProps={InputProps}
          onChange={onChange}
          value={value}
          multiline={multiline}
          rows={rows}
          sx={sx}
          required={required}
          inputRef={ref}
        />
      )}
    />
  );
}

export default StringControl;
