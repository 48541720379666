import {
  Alert, Box, IconButton, InputAdornment, Slide, TextField,
} from '@mui/material';
import {
  CloseRounded, InfoRounded, Visibility, VisibilityOff,
} from '@mui/icons-material';
import React, { useState } from 'react';
import { Controller } from 'react-hook-form';

function PasswordControl({
  control,
  id = 'password',
  label = 'Password',
  name = 'password',
  placeholder = 'Please enter a password.',
  autoComplete = 'on',
  allowInfoDisplay = false,
  sx = {},
}: PasswordControlProps) {
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordInfo, setShowPasswordInfo] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleMouseDownPassword = (e) => {
    e.preventDefault();
  };

  return (
    <Box sx={{ textAlign: 'left' }}>

      {(showPasswordInfo && allowInfoDisplay)
      && (
        <Slide direction="up" in={showPasswordInfo} mountOnEnter unmountOnExit>
          <Alert aria-label="password-help" severity="info" sx={{ mt: 2, mb: 2 }}>
            We recommend a password which is a minimum of 8 characters
            and includes upper and lowercase numbers and symbols
          </Alert>
        </Slide>
      )}
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <TextField
            id={id}
            error={!!error}
            fullWidth
            helperText={error ? error.message : null}
            label={label}
            InputLabelProps={{ shrink: true }}
            name={name}
            margin="normal"
            placeholder={placeholder}
            variant="outlined"
            type={showPassword ? 'text' : 'password'}
            autoComplete={autoComplete}
            onChange={onChange}
            value={value}
            sx={sx}
            InputProps={{
              endAdornment:
  <InputAdornment position="end">
    {allowInfoDisplay
    && (
    <IconButton
      tabIndex={-1}
      aria-label="Show password info."
      onClick={() => setShowPasswordInfo(!showPasswordInfo)}
    >
      {showPasswordInfo ? <CloseRounded /> : <InfoRounded />}
    </IconButton>
    )}
    <IconButton
      tabIndex={-1}
      aria-label="toggle password visibility"
      onClick={handleClickShowPassword}
      onMouseDown={handleMouseDownPassword}
      edge="end"
    >
      {showPassword ? <Visibility /> : <VisibilityOff />}
    </IconButton>
  </InputAdornment>,
            }}
          />
        )}
      />
    </Box>
  );
};

export interface PasswordControlProps {
  /**
   * Error object provided by react-form-hooks
   */
  control: any;
  id?: string;
  label?: string;
  name?: string;
  placeholder?: string;
  autoComplete?: 'on' | 'off' | 'new-password';
  sx?: any;
  allowInfoDisplay?: boolean;
}

export default PasswordControl;
