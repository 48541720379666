import jwtDecode from 'jwt-decode';
import store from '../store/store';

const getValidToken = () => store.getState()?.auth?.accessToken || null

const check = (rules: string[]) => {
  const storedToken = getValidToken();
  let hasAccess = false;

  if (!storedToken) return false;
  if (!jwtDecode(storedToken)) return false;

  if (rules && rules.length > 0) {
    rules.forEach((rule) => {
      if ((jwtDecode(storedToken)as any).roles && (jwtDecode(storedToken)as any).roles.length > 0) {
        if ((jwtDecode(storedToken)as any).roles.indexOf(rule) >= 0) {
          hasAccess = true;
        }
      }
      if ((jwtDecode(storedToken)as any).permissions
        && (jwtDecode(storedToken)as any).permissions.length > 0) {
        if ((jwtDecode(storedToken)as any).permissions.indexOf(rule) >= 0) {
          hasAccess = true;
        }
      }
    });
  } else {
    hasAccess = true;
  }
  return hasAccess;
};

export const isSubscriber = () => check(['Admin', 'Subscriber', 'Researcher']);

const Can = (props) => (check(props.rules)
  ? props.yes()
  : props.no());

Can.defaultProps = {
  yes: () => null,
  no: () => null,
};

export { Can, check };
