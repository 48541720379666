import { AppBar, Box, Toolbar } from '@mui/material';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { logout } from '../../../store/reducers/auth/auth-reducers';
import mixPanel from '../../../third-party/mixpanel';
import HeaderAvatar from './HeaderAvatar';
import HeaderLogo from './HeaderLogo';

function DataGatherHeader() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { profile, tempFunderId } = useAppSelector((state) => state.auth);
  const onProfileClicked = () => {
    navigate('/profile');
  };

  const onSignOut = () => {
    mixPanel.track('data-validation:logout', { funderId: tempFunderId })
    dispatch(logout());
    navigate('/login');
  };

  return (
    <AppBar sx={{ height: '64px' }} color="primary">
      <Toolbar>
        <Box sx={{ flex: '1' }} />

        <Link to="/">
          <HeaderLogo />
        </Link>

        <Box sx={{ flex: '1' }} />
        {profile
        && (
        <HeaderAvatar
          displayName=""
          onLogoutItemClicked={onSignOut}
          onProfileItemClicked={onProfileClicked}
          hideProfile
        />
        )}
      </Toolbar>
    </AppBar>
  );
}
export default DataGatherHeader;
