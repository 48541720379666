import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const cleanseApi = createApi({
  reducerPath: 'dataCleanse',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const { accessToken } = (getState() as any).auth;
      if (accessToken) {
        headers.set('authorization', `Bearer ${accessToken}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getGrantsToClean: builder.query({
      query: () => ({ url: '/grants-cleanse/grants' }),
    }),
  }),
});

export const { useGetGrantsToCleanQuery } = cleanseApi;
