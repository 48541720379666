import { isRejectedWithValue, Middleware } from '@reduxjs/toolkit';

const rtkQueryErrorLogger: Middleware = () => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    const { payload } = action;
    if (payload.message.includes('Unauthorized') && !payload.message.includes('Login failed:')) {
      if (window.location.pathname !== '/login') window.location.href = `${window.location.origin}/unauthorised?redirectTo=${window.location.pathname}`;
      else window.location.href = `${window.location.origin}/unauthorised`;
    }
  }

  return next(action);
};
export default rtkQueryErrorLogger;
