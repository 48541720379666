import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  activities: [],
  currentActivity: null,
};

const activitiesSlice = createSlice({
  name: 'activities',
  initialState,
  reducers: {
    setActivities(state, action) {
      return { ...state, activities: action.payload }
    },
  },
});

export const { setActivities } = activitiesSlice.actions;
const activitiesReducer = activitiesSlice.reducer;
export default activitiesReducer
