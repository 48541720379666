export const mixPanelConfig = {
  token: process.env.REACT_APP_MIXPANEL_TOKEN,
  url: process.env.REACT_APP_MIXPANEL_URL,
  enabled: process.env.REACT_APP_MIXPANEL_ENABLED || false,
  debug: true,
};

export const jwtAuthConfig = {
  uri: process.env.REACT_APP_AUTH_API_URL,
  tokenKey: 'auth-token',
  idKey: 'auth-id',
};
