import React, { lazy } from 'react';
import { Outlet } from 'react-router-dom';
import { Can } from '../auth/Can';
import Header from '../components/common/header/Header';
import Loadable from '../components/common/loading/Loadable';

const PreStagingFunderEdit = Loadable(lazy(() => import('../pages/research/funders/PreStagingFunderEdit')));

const researchRouteDetails = [
  {
    path: '/research',
    element: (
      <div>
        <Header />
        <Outlet />
      </div>),
    children: [
      {
        path: 'funders/:funderId',
        element: (
          <Can
            rules={['Researcher', 'Admin', 'Executive']}
            yes={() => (<PreStagingFunderEdit />)}
            no={() => {
              window.location.assign('/');
            }}
          />
        ),
      },
      {
        path: 'funders/:funderId/edit/:tab',
        element: (
          <Can
            rules={['Researcher', 'Admin', 'Executive']}
            yes={() => (<PreStagingFunderEdit />)}
            no={() => {
              window.location.assign('/');
            }}
          />
        ),
      },
    ],
  },
];

export default researchRouteDetails;
