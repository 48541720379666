import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  activeStep: 0,
  userData: null,
  lastUpdated: null,
};

const dataValidationSlice = createSlice({
  name: 'data-validation',
  initialState,
  reducers: {
    setActiveStep(state, { payload }) {
      return { ...state, activeStep: payload };
    },
    updateUserData(state, { payload }) {
      return { ...state, userData: payload, lastUpdated: new Date().toISOString() }
    },
    reset(state) {
      return { ...state, userData: null, lastUpdated: null }
    },
  },
});

export const {
  setActiveStep,
  updateUserData,
} = dataValidationSlice.actions;
const dataValidationReducer = dataValidationSlice.reducer;
export default dataValidationReducer;
