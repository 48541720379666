import { DateTime } from 'luxon';

const isInThePast = (dateString: string) => {
  try {
    if (dateString === null || dateString === '') return false;
    return DateTime.fromSQL(dateString, { zone: 'utc' }) <= DateTime.now();
  } catch (e) {
    console.error('Invalid Date', dateString);
    return false;
  }
};

export const relativeDays = (original) => {
  const now = DateTime.fromISO(original);
  return now.toRelativeCalendar({ unit: 'days' });
};

export const toPrettyFormat = (original) => {
  const now = DateTime.fromISO(original);
  return now.toFormat('dd-MMM-yyyy');
}

export default isInThePast;
