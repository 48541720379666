import React, { useEffect, useRef, useState } from 'react';
import { Close, KeyboardArrowDown, Search } from '@mui/icons-material';
import {
  Box,
  Button, Checkbox, FormControlLabel, IconButton, InputAdornment, Menu, MenuItem, TextField,
} from '@mui/material';
import Scrollbar from '../scrollbar/Scrollbar';

export class MultiSelectItem {
  label: string;

  field: string;

  value: string | number | boolean;

  displayValue: string;
}

function MultiSelect(props) {
  const {
    label, onChange, options, value = [], ...other
  } = props;
  const anchorRef = useRef(null);
  const [openMenu, setOpenMenu] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [displayOptions, setDisplayOptions] = useState(options);

  const handleOpenMenu = () => {
    setOpenMenu(true);
  };
  const handleCloseMenu = () => {
    setOpenMenu(false);
  };
  const handleChange = (event) => {
    let newValue = [...value];
    if (event.target.checked) {
      newValue.push(event.target.value);
    } else {
      newValue = newValue.filter((item) => item !== event.target.value);
    }
    onChange?.(newValue);
  };

  useEffect(() => {
    if (options) {
      const filtered = (options ?? []).filter((lo) => value.indexOf(lo.value) >= 0);
      if (searchTerm && searchTerm !== '') {
        const newOptions = (options ?? [])
          .filter((o) => o.value.toLowerCase().indexOf(searchTerm.toLowerCase()) >= 0);
        setDisplayOptions(new Set([...filtered, ...newOptions]));
      } else {
        setDisplayOptions([...new Set([...filtered, ...options])]);
      }
    }
  }, [searchTerm, options]);

  return (
    <>
      <Button
        color="inherit"
        endIcon={<KeyboardArrowDown fontSize="small" />}
        onClick={handleOpenMenu}
        ref={anchorRef}
        {...other}
      >
        {label}
      </Button>
      <Menu
        anchorEl={anchorRef.current}
        onClose={handleCloseMenu}
        open={openMenu}
        PaperProps={{
          style: {
            width: 300, height: 300, padding: 2, overflow: 'hidden',
          },
        }}
      >
        <Box
          sx={{ p: 2 }}
        >
          <TextField
            value={searchTerm}
            fullWidth
            onChange={
              (e) => setSearchTerm(e.target.value)
            }
            size="small"
            InputProps={{
              startAdornment: <InputAdornment position="start"><Search /></InputAdornment>,
              endAdornment:
  <InputAdornment position="end">
    <IconButton
      onClick={() => setSearchTerm('')}
    >
      <Close />
    </IconButton>
  </InputAdornment>,
            }}
          />
        </Box>
        <Scrollbar sx={{ maxHeight: 200 }}>
          {displayOptions.map((option) => (
            <MenuItem key={option.label}>
              <FormControlLabel
                control={(
                  <Checkbox
                    checked={value.includes(option.value)}
                    onChange={handleChange}
                    value={option.value}
                  />
                )}
                label={option.label}
                sx={{
                  flexGrow: 1,
                  mr: 0,
                }}
              />
            </MenuItem>
          ))}
        </Scrollbar>
      </Menu>
    </>
  );
}

export default MultiSelect;
