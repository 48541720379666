import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  plan: 'free',
  interval: 'free-1',
  amount: 0,
  customerId: '',
};

const paymentSlice = createSlice({
  name: 'payments',
  initialState,
  reducers: {
    setPlanDetails(state, { payload }) {
      const { plan, interval, amount } = payload;
      return {
        ...state, plan, interval, amount,
      };
    },
    setCustomerId(state, { payload }) {
      return { ...state, customerId: payload };
    },
    restPayment(state) {
      return { ...state, ...initialState }
    },
  },
});

export const {
  setCustomerId,
  setPlanDetails,
  restPayment,
} = paymentSlice.actions;
const paymentSliceReducer = paymentSlice.reducer;
export default paymentSliceReducer;
