import { createSlice } from '@reduxjs/toolkit';
import { Order } from '../../pages/admin/users/UsersTable';

export const initialState = {
  pageIndex: 0,
  pageSize: 10,
  totalFilteredRecords: 0,
  totalRecords: 0,
  users: [],
  order: 'asc' as Order,
  orderBy: 'firstName',
  confirmDeleteUserDialogOpen: false,
  selectedUser: null,
  searchTermName: '',
  where: {},
};

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    resetUsersFilters(state) {
      return { ...state, ...initialState };
    },
    setUsersPageIndex(state, { payload }) {
      return { ...state, pageIndex: payload };
    },
    setUsersPageSize(state, { payload }) {
      return { ...state, pageSize: payload };
    },
    setOrderBy(state, { payload }) {
      const { orderBy, order } = payload;
      return { ...state, orderBy, order }
    },
    setUsersRecordCounts(state, {
      payload: {
        totalFilteredRecords,
        totalRecords,
      },
    }) {
      return {
        ...state,
        totalFilteredRecords,
        totalRecords,
      };
    },
    setUsers(state, { payload }) {
      return {
        ...state,
        users: payload,
      };
    },
    setCurrentUser(state, { payload }) {
      return {
        ...state,
        selectedUser: payload,
      }
    },
    setDeleteUserDialogOpen(state, { payload }) {
      return {
        ...state,
        confirmDeleteUserDialogOpen: payload.dialogOpen,
        selectedUser: payload.selectedUser,
      };
    },
    setSearchTermName(state, { payload }) {
      return { ...state, searchTermName: payload };
    },
    setWhereFilter(state, { payload }) {
      return { ...state, where: payload };
    },
  },
});

export const {
  setUsersPageIndex,
  setUsersPageSize,
  resetUsersFilters,
  setUsers,
  setUsersRecordCounts,
  setDeleteUserDialogOpen,
  setCurrentUser,
  setOrderBy,
  setWhereFilter,
  setSearchTermName,
} = usersSlice.actions;
const usersReducer = usersSlice.reducer;
export default usersReducer;
