import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import React from 'react';
import { Controller } from 'react-hook-form';

function FormInputRadio({
  name, control, options, label,
}) {
  const generateRadioOptions = () => options.map((singleOption) => (
    <FormControlLabel
      key={`fc-${singleOption.value}-${singleOption.label}`}
      value={singleOption.value}
      label={singleOption.label || label}
      control={<Radio />}
    />
  ));
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => (
        <RadioGroup value={value} onChange={onChange}>
          {generateRadioOptions()}
        </RadioGroup>
      )}
    />
  );
}

export default FormInputRadio;
