import { Box, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setIsLoading } from '../../store/reducers/global-app/global-app.reducer';

function ErrorTempAccessAccount({ tempFunderId }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(setIsLoading(false));
  }, []);
  useEffect(() => {
    navigate(`/data-validation/funder/${tempFunderId}`);
  }, [tempFunderId])
  return (
    <Box sx={{ p: 4 }}>
      <Typography variant="h1">No Access</Typography>
    </Box>
  );
}

export default ErrorTempAccessAccount;
