import { createSlice, current } from '@reduxjs/toolkit';

export const initialState = {
  isLoading: true,
  alerts: [],
};

export class AlertDetails {
  message: string;

  severity: 'info' | 'warning' | 'success' | 'error';

  variant: 'filled' | 'outlined';

  styles?: any;
}

const globalAppSlice = createSlice({
  name: 'global-app',
  initialState,
  reducers: {
    setIsLoading(state, { payload }) {
      return { ...state, isLoading: payload };
    },
    removeAlert(state, { payload }) {
      const alerts = current(state.alerts)?.filter((n) => n !== payload);
      return { ...state, alerts };
    },
    addAlert(state, { payload }) {
      const alerts = [payload, ...state.alerts];
      return { ...state, alerts };
    },
    clearAlerts(state) {
      return { ...state, alerts: [] };
    },
  },
});

export const {
  setIsLoading,
  addAlert,
  clearAlerts,
  removeAlert,
} = globalAppSlice.actions;
const globalAppReducer = globalAppSlice.reducer;
export default globalAppReducer;
