import { v4 as uuid } from 'uuid';

const getBlankContact = () => ({
  id: uuid(),
  title: '',
  firstName: '',
  lastName: '',
  position: '',
  phone: '',
  officePhone: '',
  email: '',
});

const getBlankGrant = () => ({
  id: uuid(),
  fundProgram: 'GRANT PROGRAM',
  overview: '',
  currency: '',
  url: '',
  closesOn: null,
  opensOn: null,
  maxAmountGiven: '',
  minAmountGiven: '',
  isOpenToThePublic: true,
  isArchived: false,
  applicationType: '',
  excludedAreas: [],
  provides: [],
  dates: [{
    id: uuid(),
    closesOn: null,
    opensOn: null,
  }],
  create: true,
})

const getDefaultFunderValues = (tempFunderId) => ({
  id: tempFunderId,
  name: '',
  email: '',
  website: '',
  phone: '',
  twitter: '',
  facebook: '',
  instagram: '',
  linkedin: '',
  activities: [],
  beneficiaries: [],
  areas: [],
  notes: '',
  is_verified: false,
  tell_story: false,
  part_of_community: false,
  review_reminder: false,
  review_reminder_on: null,
  create: true,
  grants: [{
    id: uuid(),
    fundProgram: '',
    overview: '',
    exclusionCriteria: undefined,
    applicationDetails: '',
    currency: '',
    closesOn: null,
    opensOn: null,
    url: '',
    maxAmountGiven: '',
    minAmountGiven: '',
    isOpenToThePublic: true,
    isArchived: false,
    applicationType: '',
    provides: [],
    themes: [],
    excludedAreas: [],
  }],
  contacts: [getBlankContact()],
  address: {
    line1: '',
    line2: '',
    line3: '',
    line4: '',
    line5: '',
    postcode: '',
  },
})

export { getDefaultFunderValues, getBlankContact, getBlankGrant };
