import {
  createTheme, CssBaseline, StyledEngineProvider, ThemeProvider,
} from '@mui/material';
import React, { PropsWithChildren } from 'react';
import { useAppSelector } from '../store/hooks';
import { lightTheme, darkTheme } from './theme';

function CustomThemeProvider(props: PropsWithChildren<any>) {
  const { children } = props;
  const darkMode = useAppSelector((state) => state.darkMode);
  const theme = createTheme(darkMode ? darkTheme : lightTheme);
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default CustomThemeProvider;
