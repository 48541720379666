import React, { Suspense } from 'react';
import LoadingScreen from './LoadingScreen';

const Loadable = (Component: any) => function GetElement(props: any) {
  return (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  )
};

export default Loadable;
