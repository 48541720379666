import React, { lazy } from 'react';
import { Outlet } from 'react-router-dom';
import AuthProvider from '../auth/AuthProvider';
import { Can } from '../auth/Can';
import Header from '../components/common/header/Header';
import Loadable from '../components/common/loading/Loadable';

const AdminHome = Loadable(lazy(() => import('../pages/admin/AdminHome')));
const OrganisationList = Loadable(lazy(() => import('../pages/admin/organisations/OrganisationList')));
const OrganisationEdit = Loadable(lazy(() => import('../pages/admin/organisations/OrganisationEdit')));
const StagingCleanseEditFund = Loadable(lazy(() => import('../pages/cleanse/DataCleanseGrantsEdit')));
const UsersList = Loadable(lazy(() => import('../pages/admin/users/UsersList')));
const UserView = Loadable(lazy(() => import('../pages/admin/users/UserView')));
const PreStagingFunderReviewedList = Loadable(lazy(() => import('../pages/admin/pre-staging/PreStagingFunderReviewedList')));
const PreStagingFunderReviewedDetails = Loadable(lazy(() => import('../pages/admin/pre-staging/PreStagingFunderReviewedDetails')));

const adminRouteDetails = [
  {
    path: '/Admin',
    element: (
      <AuthProvider>
        <Can
          rules={['Admin', 'Executive']}
          yes={() => (
            <div>
              <Header />
              <Outlet />
            </div>
          )}
          no={() => {
            window.location.assign('/');
          }}
        />
      </AuthProvider>),
    children: [
      {
        path: 'grants/:stagingFundId/edit',
        element: <StagingCleanseEditFund />,
      },
      {
        path: 'grants/:stagingFundId/edit/:tab',
        element: <StagingCleanseEditFund />,
      },
      {
        path: '',
        element: <AdminHome />,
      },
      {
        path: 'organisations',
        children: [
          {
            path: '',
            element: <OrganisationList />,
          },
          {
            path: ':organisationId/edit/:tab',
            element: <OrganisationEdit />,
          },
        ],
      },
      {
        path: 'users',
        children: [
          {
            path: '',
            element: <UsersList />,
          },
          {
            path: ':userId/view/:tab',
            element: <UserView />,
          },
        ],
      },
      {
        path: 'pre-staging',
        children: [
          {
            path: 'reviewed',
            element: <PreStagingFunderReviewedList />,
          },
          {
            path: 'reviewed/:reviewId',
            element: <PreStagingFunderReviewedDetails />,
          },
        ],
      },
    ],
  },
];

export default adminRouteDetails;
