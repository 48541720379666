import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Alert,
  CardActions,
  CardContent,
  CardHeader,
  Container,
  Divider,
  FormControlLabel,
  Grid,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Radio,
  RadioGroup,
  Skeleton,
  Typography,
  useTheme,
} from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useCustomerCreateMutation, useProductsQuery } from '../../../../api/generated';
import { check } from '../../../../auth/Can';
import ErrorPanel from '../../../../components/common/display-panels/ErrorPanel';
import FullLogo from '../../../../components/common/logos/FullLogo';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { setIsLoading } from '../../../../store/reducers/global-app/global-app.reducer';
import { setCustomerId, setPlanDetails } from '../../../../store/reducers/payments.reducer';

const fundingItems = {
  community: {
    items: [
      'Charities Search',
      'Grant Finding',
      'Data from multiple sources',
      'Data tailored to individuals',
    ],
  },
  'community_+': {
    items: [
      'All Community Edition features',
      'Enhanced data',
      'Saved Searches',
      'Advanced Personalisation',
      'Notifications & Reminders',

    ],
  },
  partner: {
    items: [
      'All Community Plus features',
      'Additional Organisation features',
      'Access to the roadmap',
    ],
  },
};

function PaymentPlans() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { profile } = useAppSelector((state) => state.auth);
  const { isLoading } = useAppSelector((state) => state.globalApp);
  const theme = useTheme();
  const { data: productData, error } = useProductsQuery();
  const [createCustomer] = useCustomerCreateMutation();
  const {
    getValues, handleSubmit, setValue, watch, formState,
  } = useForm({
    defaultValues: {
      plan: 'free',
      interval: 'free-1',
      description: '',
      amount: '',
    },
  });
  const { isValid } = formState;
  const [pageLoading, setPageLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');

  if (error) {
    return (
      <ErrorPanel title={error?.message ? error.message : 'Error Loading payment plans'}>
        <Typography variant="body1">
          There was a problem loading the payment plans please contact
          <Link href="mailto:support@fundify.org.uk">support@fundify.org.uk</Link>
        </Typography>
        {check(['Admin']) || <pre>{JSON.stringify(error, null, 2)}</pre>}
      </ErrorPanel>
    );
  }

  const onSubmit = async (data) => {
    const { plan, interval, amount } = data;
    dispatch(setPlanDetails({
      plan,
      interval,
      amount,
    }));
    const newCustomer = {
      name: profile.organisation ? profile.organisation.name : profile.displayName,
      email: profile.email,
      phone: profile.phone || undefined,
      userId: profile.id,
    };
    const results = await createCustomer({ customerDetails: { ...newCustomer } });
    if (('error' in results)) {
      setErrorMessage('There was an issue creating the account.');
    }
    if (('data' in results)) {
      if (results.data?.customerCreate?.stripeId) {
        dispatch(setCustomerId(results.data.customerCreate.stripeId));
        setTimeout(() => {
          if (plan !== 'free') {
            navigate('/payment/create');
          } else {
            navigate('/')
          }
        }, 100)
      }
    }
  };

  useEffect(() => {
    dispatch(setIsLoading(false));
  }, []);

  useEffect(() => {
    if (productData?.products?.length > 0) {
      setPageLoading(false);
    }
  }, [productData]);

  return (
    <Container maxWidth={false} className="register-wizard" sx={{ mb: 8, pb: 12 }}>
      <Grid
        container
        justifyContent="center"
      >
        <Grid
          item
          xs={12}
          md={10}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              mb: 3,
            }}
          >
            <FullLogo
              color={theme.palette.primary.main}
              sx={{
                width: '200px',
                height: '80px',
              }}
            />
          </Box>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="stretch"
            sx={{ md: { minWidth: '960px' } }}
          >
            <form id="plan-select-form" onSubmit={handleSubmit(onSubmit)}>
              <Grid direction="column" container spacing={2}>
                <Card>
                  <CardHeader title="Select a plan" />
                  <Divider />
                  <Box sx={{ p: 2 }}>
                    <Alert severity="info">
                      <Typography
                        variant="subtitle2"
                        textAlign="center"
                      >
                        {/* eslint-disable-next-line max-len */}
                        For those who are interested in using our services and want to help improve the quality of
                        available grant
                        {/* eslint-disable-next-line max-len */}
                        data but find the outlay cost prohibitive. We have a number of additional options. Please
                        contact us via
                        {' '}
                        <Link href="mailto:support@fundify.org.uk">sales@fundify.org.uk</Link>
                      </Typography>
                    </Alert>
                  </Box>
                  <Box sx={{ p: 2 }}>
                    {
                      (errorMessage) && (
                        <ErrorPanel
                          title="Error!"
                          sx={{ mb: 2 }}
                        >
                          <Typography variant="subtitle1">{errorMessage}</Typography>
                        </ErrorPanel>
                      )
                    }
                  </Box>
                  {pageLoading && (
                    <Grid container sx={{ p: 2, pt: 0.5 }} spacing={2}>
                      {[0, 1, 2].map(() => (
                        <Grid item xs={4}>
                          <Skeleton height="200px" />
                          <Skeleton width="100%" />
                          <Skeleton width="100%" />
                          <Skeleton width="80%" />
                        </Grid>
                      ))}
                    </Grid>
                  )}
                  {!pageLoading && (
                    <Grid className="priceItem__details" item container spacing={4} sx={{ p: 3 }}>
                      {
                        [...productData.products, {
                          id: 'partner',
                          name: 'Partner',
                          description: 'COMING SOON!!! An additional level of relationship with us that has even more features.',
                          prices: [],
                        }].map((product) => (
                          <Grid key={`product-${product.id}`} item xs={12} md={4}>
                            <Card
                              sx={{
                                border: '2px solid',
                                borderColor: 'primary.main',
                                borderWidth: 1,
                                m: '-1px',
                                ...(getValues('plan') === product.id && {
                                  borderColor: 'primary.main',
                                }),
                                height: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                              }}
                            >
                              <CardHeader
                                title={product.name}
                                sx={{
                                  background: theme.palette.primary.main,
                                  color: theme.palette.primary.contrastText,
                                }}
                              />
                              <CardContent sx={{ flex: '1' }}>
                                <Grid
                                  container
                                  direction="column"
                                  justifyContent="space-between"
                                  alignItems="flex-start"
                                  sx={{ height: '100%' }}
                                >
                                  <Grid
                                    container
                                    direction="row"
                                    item
                                  >
                                    <Typography
                                      color="textSecondary"
                                      paragraph
                                      variant="body1"
                                      textAlign="center"
                                    >
                                      {product.description}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs sx={{ width: '100%' }}>
                                    <List
                                      dense
                                      sx={{ p: 0 }}
                                    >
                                      {fundingItems[product.name.replace(' ', '_').toLowerCase()]
                                        ? fundingItems[product.name.replace(' ', '_').toLowerCase()]
                                          .items.map((item) => (
                                            <ListItem
                                              key={`key-${item}`}
                                              disablePadding
                                              sx={{
                                                borderBottomStyle: 'solid',
                                                borderColor: 'primary.main',
                                                borderWidth: '1px',
                                                pb: 1,
                                                mb: 1,
                                              }}
                                            >
                                              <ListItemText
                                                primary={item}
                                              />
                                              <ListItemIcon sx={{ minWidth: '24px' }}>
                                                <CheckCircleIcon fontSize="small" color="primary" />
                                              </ListItemIcon>
                                            </ListItem>
                                          )) : null}
                                    </List>
                                  </Grid>
                                </Grid>
                              </CardContent>
                              <CardActions
                                sx={{ alignItems: 'start', minHeight: '70px' }}
                              >
                                {product.id === 'partner' ? (
                                  <Typography
                                    variant="subtitle2"
                                    textAlign="center"
                                  >
                                    To discuss this option contacts us via
                                    {' '}
                                    <Link href="mailto:support@fundify.org.uk">sales@fundify.org.uk</Link>
                                  </Typography>
                                ) : (
                                  <RadioGroup sx={{ width: '100%' }}>
                                    {product.prices.map((price) => (
                                      <Box
                                        key={`price-${price.id}`}
                                        sx={{
                                          justifyContent: 'flex-start',
                                          display: 'flex',
                                          pl: 2,
                                          width: '100%',
                                        }}
                                      >
                                        <FormControlLabel
                                          control={(
                                            <Radio
                                              checked={watch('interval') === price.id}
                                              sx={{
                                                p: 0,
                                                color: theme.palette.primary.main,
                                                '&.Mui-checked': {
                                                  color: theme.palette.primary.contrastText,
                                                },
                                              }}
                                            />
                                          )}
                                          key={price.id}
                                          onChange={() => {
                                            setValue('plan', product.id);
                                            setValue('interval', price.id);
                                            setValue('description', `${product.name} priced at: £${price.amount} per ${price.interval}`);
                                          }}
                                          label={(
                                            <Box sx={{ ml: 2, display: 'flex' }}>
                                              <Typography variant="subtitle2" sx={{ pr: 1 }}>
                                                £
                                                {price.amount}
                                              </Typography>
                                              <Typography
                                                variant="body2"
                                                sx={{
                                                  textTransform: 'capitalize',
                                                }}
                                              >
                                                {price.amount > 0 ? ' / ' : ''}
                                                {price.interval}
                                              </Typography>
                                            </Box>
                                          )}
                                          value={price.id}
                                          sx={{
                                            p: 2,
                                            mb: 1,
                                            border: '1px solid',
                                            borderColor: theme.palette.primary.main,
                                            width: '100%',
                                            borderRadius: 1,
                                            ...(getValues('interval') === price.id && {
                                              background: theme.palette.primary.main,
                                              color: theme.palette.primary.contrastText,
                                            }),
                                          }}
                                        />
                                      </Box>
                                    ))}
                                  </RadioGroup>
                                )}
                              </CardActions>
                            </Card>
                          </Grid>
                        ))
                      }
                    </Grid>
                  )}
                  <CardActions>
                    <LoadingButton
                      color="primary"
                      disabled={!isValid}
                      form="plan-select-form"
                      loading={isLoading}
                      type="submit"
                      variant="contained"
                      fullWidth
                    >
                      Next Step
                    </LoadingButton>
                  </CardActions>
                </Card>
              </Grid>
            </form>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}

export default PaymentPlans;
