import { ArrowForward, Search } from '@mui/icons-material';
import {
  Box,
  Button,
  CardContent,
  CardHeader,
  Chip,
  CircularProgress,
  Container,
  Divider,
  Grid,
  TablePagination,
  Typography,
} from '@mui/material';
import { v4 as uuid } from 'uuid';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import Input from '@mui/material/Input';
import { debounce } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SortOrder, useBeneficiariesQuery } from '../../api/generated';
import ErrorPanel from '../../components/common/display-panels/ErrorPanel';
import MultiSelect from '../../components/common/forms/MultiSelect';
import ArrowLeft from '../../components/icons/ArrowLeft';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { setIsLoading } from '../../store/reducers/global-app/global-app.reducer';
import {
  setGrantsByPage,
  setGrantsPageSize,
  setTotalFiltered,
  setSelectedThemes,
  setSelectedBeneficiaries,
  resetGrantSearchFilters,
  setSelectedProvides,
  setSelectedLocalAuthorities,
  setSearchTerm,
  setSelectedRegions,
  setSelectedMetropolitanCounties,
  GrantByDateProps,
} from '../../store/reducers/grant-by-date-reducer';
import { toPrettyFormat } from '../../utils/date-tools';
import {
  useAreasOfOperationQuery,
  useFindUpcomingFundsQuery,
  useProvidesQuery,
  useThemesQuery,
} from './api.funds-by-date';
import FundFilterDisplay from './FundFilterDisplay';

function GrantCard({
  grant, selectedThemes, selectedBeneficiaries, selectedProvides, selectedAreas, searchTerm,
}) {
  const navigate = useNavigate();

  function getHighlightedText(text, highlight) {
    // Split on highlight term and include term into parts, ignore case
    const parts = text.split(new RegExp(`(${highlight})`, 'gi'));
    return (
      <span>
        {' '}
        {parts.map((part) => (
          <span
            key={uuid()}
            style={part.toLowerCase() === highlight.toLowerCase() ? {
              fontWeight: 'bold',
              background: 'aquamarine',
            } : {}}
          >
            {part}
          </span>
        ))}
        {' '}
      </span>
    );
  }

  return (
    <Card
      variant="outlined"
      sx={{ mb: 4 }}
    >
      <CardHeader title={getHighlightedText(grant.name, searchTerm)} />
      <Divider />
      <CardContent>
        <Typography paragraph variant="body1">
          Funder:
          {' '}
          {getHighlightedText(grant.funderName, searchTerm)}
        </Typography>
        <Typography paragraph variant="body1">{getHighlightedText(grant.overview, searchTerm)}</Typography>

        {!grant.dates && (
          <Typography variant="subtitle2">
            No Dates Available!!
          </Typography>
        )}
        {grant.dates.map((date) => (
          <Grid key={`date-${Math.random()}`} container spacing={4} sx={{ mb: 2 }}>
            <Grid item sx={{ display: 'flex' }}>
              <Typography variant="subtitle1" sx={{ mr: 2 }}>
                Closes on:
              </Typography>
              <Typography color="textSecondary" variant="subtitle1">
                {toPrettyFormat(date.closesOn)}
              </Typography>
            </Grid>
            <Grid item sx={{ display: 'flex' }}>
              <Typography variant="subtitle1" sx={{ mr: 2 }}>
                Open From:
              </Typography>
              <Typography color="textSecondary" variant="subtitle1">
                {toPrettyFormat(date.opensOn)}
              </Typography>
            </Grid>

          </Grid>
        ))}
        <Box sx={{ mb: 2 }}>
          <Typography variant="subtitle1">Provides</Typography>
          {(grant?.provides || []).map((keyword) => (
            <Chip
              key={`${keyword}-${Math.random()}`}
              label={keyword}
              color="primary"
              variant={selectedProvides.includes(keyword) ? 'filled' : 'outlined'}
              sx={{ mr: 1, mb: 0.5 }}
            />
          ))}
        </Box>
        <Box sx={{ mb: 2 }}>
          <Typography variant="subtitle1">Themes</Typography>
          {(grant?.themes || []).map((theme) => (
            <Chip
              key={`${theme}-${Math.random()}`}
              label={theme}
              color="primary"
              variant={selectedThemes.includes(theme) ? 'filled' : 'outlined'}
              sx={{ mr: 1, mt: 1 }}
            />
          ))}
        </Box>
        <Box sx={{ mb: 2 }}>
          <Typography variant="subtitle1">Beneficiaries</Typography>
          {(grant?.beneficiaries || []).map((theme) => (
            <Chip
              key={`${theme}-${Math.random()}`}
              label={theme}
              color="primary"
              variant={selectedBeneficiaries.includes(theme) ? 'filled' : 'outlined'}
              sx={{ mr: 1, mt: 1 }}
            />
          ))}
        </Box>
        <Box sx={{ mb: 2 }}>
          <Typography variant="subtitle1">Areas</Typography>
          {(grant?.aoo || []).map((theme) => (
            <Chip
              key={`${theme}-${Math.random()}`}
              label={theme}
              color="primary"
              variant={selectedAreas.includes(theme) ? 'filled' : 'outlined'}
              sx={{ mr: 1, mt: 1 }}
            />
          ))}
        </Box>
        <Box sx={{ mb: 2 }}>
          <Typography variant="subtitle1">Keywords</Typography>
          {(grant?.keywords || []).map((keyword) => (
            <Chip
              key={`${keyword}-${Math.random()}`}
              label={keyword}
              color="primary"
              variant="outlined"
              sx={{ mr: 1, mb: 0.5 }}
            />
          ))}
        </Box>
      </CardContent>
      <Divider />
      <CardActions sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          endIcon={<ArrowForward fontSize="small" />}
          size="small"
          aria-label="View the grant"
          onClick={() => navigate(`/search/grant/${grant.id}`)}
        >
          View Grant
        </Button>
      </CardActions>
    </Card>
  );
}

function FundsByDate() {
  const dispatch = useAppDispatch();
  const {
    pageIndex,
    pageSize,
    totalFilteredRecords,
    searchTerm,
    selectedThemes,
    selectedAreas,
    selectedRegions,
    selectedBeneficiaries,
    selectedProvides,
    selectedLocalAuthorities,
    selectedMetropolitanCounties,
  } = useAppSelector<GrantByDateProps>((state) => state.grantsByDate);
  const navigate = useNavigate();
  const { data: themeResponse } = useThemesQuery({
    themeSearchDetails: {
      orderBy: [{
        name: SortOrder.Asc,
      }],
    },
  });
  const { data: beneficiariesResponse } = useBeneficiariesQuery({
    beneficiariesSearchDetails: {
      orderBy: [{
        name: SortOrder.Asc,
      }],
    },
  });
  const { data: providesResponse } = useProvidesQuery({
    providesSearchDetails: {
      orderBy: [{
        name: SortOrder.Asc,
      }],
    },
  });
  const { data: aooResponse } = useAreasOfOperationQuery({
    aooSearchDetails: {
      orderBy: [
        {
          type: SortOrder.Asc,
        },
        {
          description: SortOrder.Asc,
        },
      ],
    },
  });
  const { data, isLoading, isError } = useFindUpcomingFundsQuery({
    grantByDate: {
      skip: pageIndex,
      take: pageSize,
      areas: selectedAreas || undefined,
      beneficiaries: selectedBeneficiaries || undefined,
      themes: selectedThemes || undefined,
      provides: selectedProvides || undefined,
      searchTerm: searchTerm || undefined,
    },
  });
  const [countries, setCountries] = useState([]);
  const [regions, setRegions] = useState([]);
  const [metropolitanCounties, setMetropolitanCounties] = useState([]);
  const [localAuthorities, setLocalAuthorities] = useState([]);

  useEffect(() => {
    if (aooResponse && aooResponse?.getAreasOfOperation?.results) {
      setCountries(aooResponse?.getAreasOfOperation?.results.filter((c) => c.type === 'Country'));
      setLocalAuthorities(aooResponse?.getAreasOfOperation?.results.filter((c) => c.type === 'Local Authority'));
      setMetropolitanCounties(aooResponse?.getAreasOfOperation?.results.filter((c) => c.type === 'Metropolitan Counties'));
      setRegions(aooResponse?.getAreasOfOperation?.results.filter((c) => c.type === 'Region'));
    }
  }, [aooResponse]);

  const handleAuthoritiesChanged = (changed) => {
    dispatch(setSelectedLocalAuthorities(changed));
  }

  const navigateBack = () => {
    navigate('/');
  };
  const handlePageChange = (event, newPageIndex) => {
    dispatch(setGrantsByPage(newPageIndex));
  };
  const handlePageSizeChange = (event) => {
    dispatch(setGrantsPageSize(parseInt(event.target.value, 10)));
  };
  const handleResetFilters = () => {
    dispatch(resetGrantSearchFilters());
  };

  const handleUserFilterChanged = (term) => {
    dispatch(setSearchTerm(term));
  };
  const searchNameHandler = useMemo(
    () => debounce((eventDetails) => handleUserFilterChanged(eventDetails.target.value), 300),
    [],
  );

  useEffect(() => {
    if (data?.findUpcomingFunds) {
      const { filteredResults, totalResults } = data.findUpcomingFunds;
      dispatch(setTotalFiltered({ filteredResults, totalResults }));
      dispatch(setIsLoading(false));
    }
  }, [data]);

  if (isError) {
    return (
      <Box sx={{ m: 2 }}>
        <ErrorPanel
          title="Server Error!!"
        >
          <Typography>There was an error loading the details</Typography>
        </ErrorPanel>
      </Box>
    );
  }
  if (isLoading) {
    return (<Box sx={{ m: 2 }}><CircularProgress /></Box>);
  }

  return (
    <Container maxWidth={false} sx={{ pt: 1 }}>
      <Box
        sx={{
          mb: 2,
          mt: 0,
          textAlign: 'left',
          width: '100%',
        }}
      >
        <Button
          startIcon={<ArrowLeft fontSize="small" />}
          size="small"
          onClick={navigateBack}
        >
          &nbsp; Back
        </Button>
      </Box>
      <Typography component="h1" align="center" variant="h3" sx={{ mb: 2 }}>Grants By Closing Date</Typography>
      <Card
        variant="outlined"
        sx={{ mb: 4 }}
      >
        <CardHeader title="Filters" />
        <Divider />
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            p: 2,
          }}
        >
          <Search fontSize="small" />
          <Box
            sx={{
              flexGrow: 1,
              ml: 3,
            }}
          >
            <Input
              disableUnderline
              fullWidth
              placeholder="Search"
              onChange={searchNameHandler}
              defaultValue={searchTerm || ''}
            />
          </Box>
        </Box>
        <Divider />
        <FundFilterDisplay />
        <Divider />
        <CardContent>
          <Typography variant="h6">Supporting</Typography>
          <Grid container>
            <Grid item>
              <MultiSelect
                label="Provides"
                onChange={(event) => dispatch(setSelectedProvides(event))}
                options={providesResponse?.provides?.results.map(
                  (t) => ({ value: t.name, label: t.name }),
                ) || []}
                value={selectedProvides}
              />
            </Grid>
            <Grid item>
              <MultiSelect
                label="Beneficiaries"
                onChange={(event) => dispatch(setSelectedBeneficiaries(event))}
                options={beneficiariesResponse?.getBeneficiaries?.results.map(
                  (t) => ({ value: t.name, label: t.name }),
                ) || []}
                value={selectedBeneficiaries}
              />
            </Grid>
            <Grid item>
              <MultiSelect
                label="Themes"
                onChange={(event) => dispatch(setSelectedThemes(event))}
                options={themeResponse?.getThemes?.results.map(
                  (t) => ({ value: t.name, label: t.name }),
                ) || []}
                value={selectedThemes}
              />
            </Grid>
          </Grid>
          <Typography variant="h6">Areas</Typography>
          <Grid id="area-filters" container>
            <Grid item>
              <MultiSelect
                label="Regions"
                onChange={(event) => dispatch(setSelectedRegions(event))}
                options={[...new Set(regions.map(
                  (t) => ({ value: t.description, label: t.description }),
                ) || [])]}
                value={selectedRegions}
              />
            </Grid>
            <Grid item>
              <MultiSelect
                label="Local Authorities"
                onChange={handleAuthoritiesChanged}
                options={localAuthorities.map(
                  (t) => ({ value: t.description, label: t.description }),
                ) || []}
                value={selectedLocalAuthorities}
              />
            </Grid>
            <Grid item>
              <MultiSelect
                label="Metropolitan Counties"
                onChange={(event) => dispatch(setSelectedMetropolitanCounties(event))}
                options={[...new Set(metropolitanCounties.map(
                  (t) => ({ value: t.description, label: t.description }),
                ) || [])]}
                value={selectedMetropolitanCounties}
              />
            </Grid>
            <Grid item>
              <MultiSelect
                label="Countries"
                onChange={(event) => dispatch(setSelectedRegions(event))}
                options={[...new Set(countries.map(
                  (t) => ({ value: t.description, label: t.description }),
                ) || [])]}
                value={selectedRegions}
              />
            </Grid>
          </Grid>
        </CardContent>
        <CardActions sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            variant="text"
            onClick={() => handleResetFilters()}
          >
            Reset
          </Button>
        </CardActions>
      </Card>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={3}
      >
        <Grid item />
        <Grid item xs>
          <TablePagination
            component="div"
            count={totalFilteredRecords}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handlePageSizeChange}
            page={pageIndex}
            rowsPerPage={pageSize}
            rowsPerPageOptions={[5, 10, 25]}
          />
        </Grid>
      </Grid>
      {(data.findUpcomingFunds?.results || []).map((grant) => (
        <GrantCard
          key={`grant-${grant.id}`}
          grant={grant}
          selectedThemes={selectedThemes}
          selectedBeneficiaries={selectedBeneficiaries}
          selectedProvides={selectedProvides}
          selectedAreas={selectedAreas}
          searchTerm={searchTerm}
        />
      ))}
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={3}
      >
        <Grid item />
        <Grid item xs>
          <TablePagination
            component="div"
            count={totalFilteredRecords}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handlePageSizeChange}
            page={pageIndex}
            rowsPerPage={pageSize}
            rowsPerPageOptions={[5, 10, 25]}
          />
        </Grid>
      </Grid>
    </Container>
  );
}

export default FundsByDate;
